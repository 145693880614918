import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import appStore from '../../images/optimized/svg/app_store.svg'
import googlePlay from '../../images/optimized/svg/google_play.svg'
import qrcode_apps from '../../images/optimized/svg/qrcode_apps.svg'
import qrcode_play from '../../images/optimized/svg/qrcode_play.svg'

import * as styles from './styles.module.scss'

const DownloadBtns = props => {
    const { className, showQr=false } = props

    const appStoreClass = classNames(styles.appStoreBtn, className)
    const googlePlayClass = classNames(styles.googlePlayBtn, className)
    const qrClass = classNames(styles.downloadApp__qr, !showQr && styles.hide)

    return (
        <div className={styles.downloadApp}>
            <div className={styles.downloadApp__block}>
                <img src={qrcode_apps} className={qrClass} alt="qr code app store" />
                <a
                    href="https://apps.apple.com/us/app/gyw-volunteers/id1587001652"
                    target="_blank"
                    className={styles.btnDownloadApp}
                    rel="noreferrer"
                >
                    <img
                        src={appStore}
                        alt="Download on the App Store"
                        className={appStoreClass}
                        data-name="app-store"
                    />
                </a>
            </div>
            <div className={styles.downloadApp__block}>
                <img
                    src={qrcode_play}
                    className={qrClass}
                    alt="qr code google play"
                />
                <a
                    href="https://play.google.com/store/apps/details?id=com.goodyourway.volunteers.app"
                    target="_blank"
                    className={styles.btnDownloadApp}
                    rel="noreferrer"
                >
                    <img src={googlePlay} alt="Get it on Google play" className={googlePlayClass} />
                </a>
            </div>
        </div>
    )
}

DownloadBtns.propTypes = {
    className: PropTypes.string,
}

export default DownloadBtns
