import axios from 'axios'
import { apiUrl } from '../../../../constants'

export const getAccessToken = async () => {
    return axios.get(`${apiUrl}/payments/access-token`)
}

export const verifyCard = async (values, token) => {
    const {
        cardNum,
        expirationMonth,
        expirationYear,
        cvc,
        nameOnCard,
        email,
        zip,
        address,
        city,
        state,
        country,
        firstname,
        lastname,
    } = values

    const url = `${window.DonationSdk.settings.ApiPath}/api/VerifyCard`
    const body = {
        CardNum: cardNum,
        ExpMonth: expirationMonth,
        ExpYear: expirationYear,
        CvNum: cvc,
        NameOnCard: nameOnCard,
        Email: email,
        Zip: zip,
        Street: address,
        City: city,
        State: state,
        Country: country,
        FirstName: firstname,
        LastName: lastname,
    }

    return axios.post(url, body, {
        headers: { AccessToken: token },
    })
}

export const tokenizeCard = async (token, values, charityId) => {
    const {
        cardNum,
        expirationMonth,
        expirationYear,
        cvc,
        nameOnCard,
        email,
        zip,
        address,
        city,
        state,
        country,
        firstname,
        lastname,
    } = values

    return window.DonationSdk.CardOnFile({
        AccessToken: token,
        CharityId: charityId,
        CardNum: cardNum,
        ExpMonth: expirationMonth,
        ExpYear: expirationYear,
        CvNum: cvc,
        NameOnCard: nameOnCard,
        Email: email,
        Zip: zip,
        Street: address,
        City: city,
        State: state,
        Country: country,
        FirstName: firstname,
        LastName: lastname,
    })
}

export const calcFees = async (tokenGuid, nonprofit_designations, amount) => {
    const url = `${apiUrl}/payments/fees`
    return axios.post(url, {
        card_token: tokenGuid,
        nonprofit_designations: nonprofit_designations,
        sponsorship_amount: amount,
    })
}

export const sendPaymentInfo = async (token, values, goal_id, cardFeeRate, totalFees) => {
    const {
        city,
        country,
        state,
        address,
        zip,
        email,
        firstname,
        lastname,
        amount,
        coverFees,
    } = values

    return axios.post(`${apiUrl}/payments`, {
        card_fee_rate: cardFeeRate,
        card_token: token,
        cover_fees: coverFees,
        donor_email: email,
        donor_name: `${firstname} ${lastname}`,
        donor_payment_details: {
            city: city,
            country: country,
            state: state,
            street: address,
            postal_code: zip,
        },
        goal_id: +goal_id,
        sponsorship_amount: Number(amount),
        total_sponsorship_amount: Number(amount) + totalFees,
    })
}
