import React from 'react'
import ReactPlayer from 'react-player'

import * as styles from './styles.module.scss'

const Video = () => {
    return (
        <section className={styles.presentation}>
            <ReactPlayer
                className={styles.presentation__video}
                url="https://www.youtube.com/embed/r6bOwiceAEk"
                controls={true}
                light={true}
            />
        </section>
    )
}

export default Video
