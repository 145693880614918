import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Checkbox from '../../../elements/Checkbox'
import Dropdown from '../../../elements/Dropdown'
import Input from '../../../elements/Input'
import SvgSprite from '../../../SvgSprite'
import { countries, USAStates, CanadaStates, AustraliaStates } from './selectOptions'

import * as styles from './styles.module.scss'

const PaymentInfo = props => {
    const [states, setStates] = useState([])
    const [showCvc, setShowCvc] = useState(true)
    const [disablSelect, setDisableSelect] = useState(true)
    const expYear = useRef(null)
    const expMonth = useRef(null)

    const cvcType = showCvc ? 'text' : 'password'

    const paymentFields = [
        {
            name: 'cardNum',
            label: 'Card number',
            placeholder: 'XXXX-XXXX-XXXX-XXXX',
        },
        {
            label: 'Expiration',
        },
        {
            name: 'cvc',
            label: 'CVC code',
            placeholder: 'XXXX',
            type: cvcType,
        },
        {
            name: 'nameOnCard',
            label: 'Name on Card',
        },
        {
            name: 'sameAsPersonal',
            label: 'Same as in Personal Information',
            type: 'checkbox',
        },
        {
            name: 'address',
            label: 'Address',
        },
        {
            name: 'zip',
            label: 'ZIP',
        },
        {
            name: 'country',
            label: 'Country',
            type: 'select',
        },
        {
            name: 'state',
            label: 'State',
            type: 'select',
        },
        {
            name: 'city',
            label: 'City',
        },
    ]

    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleClick,
        submitted,
        setFieldTouched,
        setFieldValue,
    } = props

    useEffect(() => {
        if (values.country) {
            setDisableSelect(false)
        }
        if (values.country === 'US') {
            setStates(USAStates)
        } else if (values.country === 'CA') {
            setStates(CanadaStates)
        } else if (values.country === 'AU') {
            setStates(AustraliaStates)
        }

        values.state = ''
    }, [values.country])

    useEffect(() => {
        if (values.sameAsPersonal) {
            values.nameOnCard = `${values.firstname} ${values.lastname}`
            setFieldTouched('nameOnCard')
        }
        if (!values.sameAsPersonal) {
            setFieldValue('nameOnCard', '')
        }
    }, [values.sameAsPersonal])

    useEffect(() => {
        if (values.sameAsPersonal) {
            values.nameOnCard = `${values.firstname} ${values.lastname}`
            setFieldTouched('nameOnCard')
        }
    }, [values.firstname])

    useEffect(() => {
        if (values.sameAsPersonal) {
            values.nameOnCard = `${values.firstname} ${values.lastname}`
            setFieldTouched('nameOnCard')
        }
    }, [values.lastname])

    useEffect(() => {
        if (values.expirationMonth.length === 2) {
            expYear.current.focus()
        }
    }, [values.expirationMonth])

    useEffect(() => {
        if (!values.expirationYear.length && values.expirationMonth.length) {
            expMonth.current.focus()
        }
    }, [values.expirationYear])

    const inputValueFormat = name => {
        if (name === 'cardNum' && values.cardNum.length > 0) {
            return values.cardNum
                .replace(/\W/gi, '')
                .replace(/(.{4})/g, '$1 ')
                .trim()
        }
        if (name === 'zip') {
            return values.zip.trim()
        } else {
            return values[name]
        }
    }

    const onExpChange = e => {
        setFieldValue(e.target.name, e.target.value.substring(0, 2))
    }

    const cvcIconClass = classNames(styles.payment__cvcIcon, { [styles.show]: showCvc })

    return (
        <div className={styles.payment}>
            <span className={styles.payment__accept}>We accept</span>
            <div className={styles.payment__icons}>
                <SvgSprite name="visa" />
                <SvgSprite name="masterCard" />
                <SvgSprite name="americanExpress" />
                <SvgSprite name="discover" />
                <SvgSprite name="dinersClub" />
            </div>
            {paymentFields.map(({ name, label, placeholder, type }) => {
                if (type === 'select') {
                    return (
                        <div data-name={name} className={styles.payment__wrapper} key={name}>
                            <label
                                htmlFor={name}
                                className={styles.payment__label}
                                disabled={name === 'state' && disablSelect}
                            >
                                {label}
                            </label>
                            <div
                                className={styles.payment__selectWrapper}
                                disabled={name === 'state' && disablSelect}
                            >
                                <Dropdown
                                    name={name}
                                    value={values[name]}
                                    type="select"
                                    touched={touched}
                                    errors={errors}
                                    values={values}
                                    onBlur={handleBlur}
                                    onClick={handleClick}
                                    disabled={name === 'state' && disablSelect}
                                    options={name === 'country' ? countries : states}
                                    submitted={submitted}
                                />
                                {errors[name] && touched[name] && (
                                    <p className={styles.error}>{errors[name]}</p>
                                )}
                            </div>
                        </div>
                    )
                } else if (type === 'checkbox') {
                    return (
                        <div data-name={name} className={styles.payment__wrapper} key={name}>
                            <Checkbox
                                labelText={label}
                                name={name}
                                value={values[name]}
                                type={type}
                                touched={touched}
                                errors={errors}
                                values={values}
                                onChange={handleChange}
                                onClick={handleBlur}
                                onBlur={handleBlur}
                                required={true}
                            />
                        </div>
                    )
                } else if (label === 'Expiration') {
                    return (
                        <div
                            data-name={'expiration'}
                            className={styles.payment__wrapper}
                            key={'expiration'}
                        >
                            <label htmlFor={'expiration'} className={styles.payment__label}>
                                {label}
                            </label>
                            <span className={styles.payment__expirationContainer}>
                                <input
                                    name={'expirationMonth'}
                                    ref={expMonth}
                                    value={values['expirationMonth']}
                                    onChange={onExpChange}
                                    onBlur={handleBlur}
                                    placeholder={'MM'}
                                    type="number"
                                    touched={touched}
                                    errors={errors}
                                    values={values}
                                    required={true}
                                />
                                <span>/</span>
                                <input
                                    name={'expirationYear'}
                                    ref={expYear}
                                    value={values['expirationYear']}
                                    onChange={onExpChange}
                                    onBlur={handleBlur}
                                    placeholder={'YY'}
                                    type="number"
                                    touched={touched}
                                    errors={errors}
                                    values={values}
                                    required={true}
                                />
                            </span>
                            {(touched['expirationMonth'] || touched['expirationYear']) &&
                                errors['expiration'] && (
                                    <p className={styles.error}>{errors['expiration']}</p>
                                )}
                        </div>
                    )
                } else {
                    return (
                        <div data-name={name} className={styles.payment__wrapper} key={name}>
                            <label htmlFor={name} className={styles.payment__label}>
                                {label}
                            </label>
                            <Input
                                name={name}
                                value={inputValueFormat(name)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder={placeholder}
                                className={styles.payment__input}
                                type={type}
                                touched={touched}
                                errors={errors}
                                values={values}
                                required={true}
                                autoComplete="off"
                            />
                            {name === 'cvc' && (
                                <div
                                    className={cvcIconClass}
                                    onClick={() => setShowCvc(!showCvc)}
                                />
                            )}
                        </div>
                    )
                }
            })}
        </div>
    )
}

PaymentInfo.propTypes = {
    values: PropTypes.object,
    touched: PropTypes.object,
    errors: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    handleClick: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    submitted: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default PaymentInfo
