import React, { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Card from '../Card'
import ModalGetNotified from '../ModalGetNotified'

import * as styles from './styles.module.scss'

const fundingIcon = '../../images/optimized/png/sections/forCharitableOrganizations/fundingIcon.png'
const handsIcon = '../../images/optimized/png/sections/forCharitableOrganizations/handsIcon.png'
const listIcon = '../../images/optimized/png/sections/forCharitableOrganizations/listIcon.png'
const chartIcon = '../../images/optimized/png/sections/forCharitableOrganizations/chartIcon.png'
const communicationIcon =
    '../../images/optimized/png/sections/forCharitableOrganizations/communicationIcon.png'

const data = [
    {
        img: (
            <StaticImage
                width={150}
                height={150}
                src={fundingIcon}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="funding Icon"
                layout="constrained"
                className={styles.icons}
                placeholder="tracedSVG"
            />
        ),
        title: 'Receive additional funding, at no cost',
        text:
            'Let volunteers be your advocates. Individuals can designate your organization to receive financial contributions, even if they don’t volunteer for you directly.',
    },
    {
        img: (
            <StaticImage
                width={150}
                height={150}
                src={handsIcon}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="hands Icon"
                layout="constrained"
                className={styles.icons}
                placeholder="tracedSVG"
            />
        ),
        title: 'Redefine the “volunteer rejection” experience',
        text:
            'Saying “no” when offered help takes effort and is a lost opportunity. Instead, redirect to other volunteering opportunities and offer volunteers to still support your organization as one of the designated funding recipients.',
    },
    {
        img: (
            <StaticImage
                width={150}
                height={150}
                src={listIcon}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="list Icon"
                layout="constrained"
                className={styles.icons}
                placeholder="tracedSVG"
            />
        ),
        title: 'Volunteering activity tracking, at no cost (coming later)',
        text:
            'Have access to the activity track record reported by volunteers at your organization. Use it for operational disclosures and analytics.',
    },
    {
        img: (
            <StaticImage
                width={150}
                height={150}
                src={chartIcon}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="chart icon"
                layout="constrained"
                className={styles.icons}
                placeholder="tracedSVG"
            />
        ),
        title: 'Access to data analytics, at no cost (coming later)',
        text: 'Have access to up-to-date trends in volunteering activity and charitable giving.',
    },
    {
        img: (
            <StaticImage
                width={150}
                height={150}
                src={communicationIcon}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="communication icon"
                layout="constrained"
                className={styles.icons}
                placeholder="tracedSVG"
            />
        ),
        title: 'Reach out to individuals who express interest in your organization (coming later) ',
        text:
            'Have the ability to contact individuals who have volunteered at or have designated your organization to receive financial support.',
    },
]

const ForCharitableOrganizations = () => {
    const [isOpenModal, setIsOpenModal] = useState(false)

    const toggleOpenModal = () => {
        setIsOpenModal(true)
    }

    const closeModal = () => {
        setIsOpenModal(false)
    }

    return (
        <section className={styles.ForCharitableOrganizations}>
            <span id="charities" className="anchor" />
            <div className="container">
                <h2 className={styles.title}>For charitable organizations</h2>
                <p className={styles.subTitle}>
                    By using the GYW platform, volunteers allow us to provide you with additional
                    resources at no cost.
                </p>
                <button className={styles.btn} onClick={toggleOpenModal}>
                    Get notified
                </button>
                <ul className={styles.bodyGrid}>
                    {data.map(({ title, text, img }) => (
                        <li key={title} className={styles.cardWrapper}>
                            <Card title={title} text={text} className="charitableCard">
                                <div>{img}</div>
                            </Card>
                        </li>
                    ))}
                    <li className={styles.getNotified}>
                        <h3 className={styles.getNotifiedTitle}>
                            Get notified about the upcoming free tool for charities
                        </h3>
                        <button className={styles.btn} onClick={toggleOpenModal}>
                            Get notified
                        </button>
                    </li>
                </ul>
            </div>
            {isOpenModal && <ModalGetNotified closeModal={closeModal} />}
        </section>
    )
}

export default ForCharitableOrganizations
