import React, { useState, useEffect } from 'react'
import PT from 'prop-types'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import classNames from 'classnames'
import { Location } from '@reach/router'
import { localEnv, devEnv } from '../../hostNames'
import SvgSprite from '../SvgSprite'

import * as styles from './styles.module.scss'

const menuLinks = [
    {
        text: 'Individuals',
        link: '/#individuals',
    },
    {
        text: 'Charities',
        link: '/#charities',
    },
    {
        text: 'About Us',
        link: '/#about-us',
    },
    {
        text: 'Contact us',
        link: '/#contact-us',
    },
    {
        text: 'FAQ',
        link: '/#faq',
    },
    // {
    //     text: 'Thank you',
    //     link: '/#gratitude',
    // },
    {
        text: 'How It Works',
        link: '/how-it-works',
    }
]

const prodMenuLinks = [
    {
        text: 'Individuals',
        link: '/#individuals',
    },
    {
        text: 'Charities',
        link: '/#charities',
    },
    {
        text: 'About Us',
        link: '/#about-us',
    },
    {
        text: 'Contact us',
        link: '/#contact-us',
    },
    {
        text: 'FAQ',
        link: '/#faq',
    },
    {
        text: 'How It Works',
        link: '/how-it-works',
    }
]

const Navigation = props => {
    const [open, setOpen] = useState(false)
    const [activeMenuItem, setActiveMenuItem] = useState(
        `/${props.location.pathname.split('/')[1]}`
    )

    const anchorLinks = menuLinks
        .filter(({ link }) => link.includes('#'))
        .map(({ link }) => link.split('/')[1])

    const getAnchorElements = () =>
        anchorLinks.map(link => document.querySelector(link)).filter(el => el !== null)

    const defineSectionViewport = anchorElements => {
        if (anchorElements.length > 0) {
            anchorElements.forEach(el => {
                const elOffsetTop = el.getBoundingClientRect().top

                if (elOffsetTop < 200 && elOffsetTop > 0) {
                    setActiveMenuItem(`/#${el.getAttribute('id')}`)
                }
            })
        }
    }

    useEffect(() => {
        let anchorElements = []

        if (props.location.hash) history.pushState('', document.title, window.location.pathname)

        setTimeout(() => {
            anchorElements = getAnchorElements()
            defineSectionViewport(anchorElements)
            window.addEventListener('scroll', () => defineSectionViewport(anchorElements))
        }, 100)

        return window.removeEventListener('scroll', () => defineSectionViewport(anchorElements))
    }, [])

    const openClass = classNames({ [styles.open]: open })
    const burgerOpenClass = classNames(styles.burger, openClass)
    const listOpenClass = classNames(styles.list, openClass)
    const classNameNav = classNames({ [styles.navFooter]: props.footer })

    return (
        <div className={classNameNav}>
            <div
                className={burgerOpenClass}
                onClick={() => {
                    setOpen(!open)
                }}
            >
                <div className={styles.strip} />
                <div className={styles.strip} />
                <div className={styles.strip} />
            </div>
            <SvgSprite name="logoMobileWhite" className={styles.logo} />
            <nav className={styles.nav}>
                <ul className={listOpenClass}>
                    {(localEnv || devEnv ? menuLinks : prodMenuLinks).map(({ text, link }) => (
                        <li
                            key={text}
                            className={styles.item}
                            onClick={() => {
                                setOpen(false)
                            }}
                        >
                            <AnchorLink
                                offset={-100}
                                to={link}
                                title={text}
                                className={`${styles.link} ${
                                    activeMenuItem === link ? 'active' : ''
                                }`}
                            >
                                {text}
                            </AnchorLink>
                        </li>
                    ))}
                </ul>
                <div
                    className={styles.getApp}
                    onClick={() => {
                        setOpen(false)
                    }}
                >
                    <AnchorLink to="/#download-app">
                        Get
                        <SvgSprite name="getAppWhite" className={styles.app} />
                        app
                    </AnchorLink>
                </div>
            </nav>
        </div>
    )
}

const wrappNavigation = ({ footer }) => {
    return (
        <Location>{({ location }) => <Navigation location={location} footer={footer} />}</Location>
    )
}

wrappNavigation.propTypes = {
    footer: PT.bool,
}

Navigation.propTypes = {
    location: PT.object.isRequired,
    footer: PT.bool,
}

export default wrappNavigation
