// extracted by mini-css-extract-plugin
export var donation = "styles-module--donation--nPLd4";
export var donation__title = "styles-module--donation__title--SkHgT";
export var donation__form = "styles-module--donation__form--SFW2S";
export var donation__formBlock = "styles-module--donation__formBlock--Y4Ikr";
export var donation__formContent = "styles-module--donation__formContent--xSevn";
export var donation__step = "styles-module--donation__step--PWR1p";
export var donation__spiner = "styles-module--donation__spiner--t0cSo";
export var donationModal = "styles-module--donationModal--FgkeE";
export var modalMessage = "styles-module--modalMessage--Jsiz-";
export var decline = "styles-module--decline--HPzPt";