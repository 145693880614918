import React, { useEffect } from 'react'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'

import SvgSprite from '../SvgSprite'
import Navigation from '../Navigation'
import SocialLinks from '../SocialLinks'

import * as styles from './styles.module.scss'

const Footer = () => {
    useEffect(() => {
        window.dataLayer = window.dataLayer || []
        const gtag = () => {
            window.dataLayer.push(arguments)
        }
        gtag('js', new Date())
        gtag('config', 'G-0V10NWEQ0R')
    }, [])

    const footerWrapClass = classNames(styles.wrapper, 'container')
    const currentYear = new Date(Date.now()).getFullYear()
    return (
        <>
            <Helmet>
                <script
                    async
                    src="https://www.googletagmanager.com/gtag/js?id=G-0V10NWEQ0R"
                ></script>
            </Helmet>
            <footer className={styles.footer}>
                <div className={footerWrapClass}>
                    <div className={styles.logoContainer}>
                        <SvgSprite name="logoWhite" />
                    </div>
                    <div className={styles.socialsDesktop}>
                        <SocialLinks />
                    </div>
                    <div className={styles.navInfo}>
                        <div className={styles.nav}>
                            <Navigation footer={true} />
                        </div>
                        <div className={styles.privacy}>
                            <span>&copy; {currentYear} Good Your Way, Inc</span>
                            <a
                                className={styles.privacy__link}
                                href="/terms-of-use"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Terms of Use
                            </a>
                            <a
                                className={styles.privacy__link}
                                href="/privacy-policy"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Privacy Policy
                            </a>
                        </div>
                        <div className={styles.socialsMobile}>
                            <SocialLinks />
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
