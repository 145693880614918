import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import BaseContainer from 'src/components/BaseContainer'
import FundraisingPage from '../../components/FundraisingPage'

const Fundraising = props => {
    const goalId = props['*']
    return (
        <>
            <Helmet>
                <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js" />
                <script src="https://payments.frontstream.com/js/DonationSdk.min.js" />
            </Helmet>
            <BaseContainer header={false}>
                <FundraisingPage goalId={goalId} />
            </BaseContainer>
        </>
    )
}

Fundraising.propTypes = {
    '*': PropTypes.string,
}

export default Fundraising
