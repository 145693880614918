// extracted by mini-css-extract-plugin
export var amount__inputWrapper = "styles-module--amount__inputWrapper--DEGQu";
export var amount__checkboxWrapper = "styles-module--amount__checkboxWrapper--3uoj7";
export var fees__disclosure = "styles-module--fees__disclosure---DcIw";
export var amount__label = "styles-module--amount__label--b7oyp";
export var amount__input = "styles-module--amount__input--ubqty";
export var amount__inform = "styles-module--amount__inform---N5NQ";
export var amount__button = "styles-module--amount__button--dFs5D";
export var fees = "styles-module--fees--whiBA";
export var fees__mainInfo = "styles-module--fees__mainInfo--rJa2z";
export var fees__label = "styles-module--fees__label--NNsim";
export var fees__quantity = "styles-module--fees__quantity--k7Fwu";
export var fees__calc = "styles-module--fees__calc--oVlfu";
export var fees__details = "styles-module--fees__details--zmFod";
export var spiner = "styles-module--spiner--+Dkn-";
export var feesModal = "styles-module--feesModal--f3cyQ";
export var modalMessage = "styles-module--modalMessage--qxszE";
export var error = "styles-module--error--hrqPG";