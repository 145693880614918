import React from 'react'
import PropTypes from 'prop-types'

import Input from '../../../elements/Input'
import * as styles from './styles.module.scss'

const PersonalInfo = props => {
    const personalFields = [
        {
            name: 'firstname',
            label: 'First Name',
        },
        {
            name: 'lastname',
            label: 'Last Name',
        },
        {
            name: 'email',
            label: 'Email',
            placeholder: 'Your receipt will be emailed here',
        },
    ]

    const { values, touched, errors, handleChange, handleBlur } = props

    return (
        <div className={styles.personal}>
            {personalFields.map(({ name, label, placeholder, type }) => (
                <div data-name={name} className={styles.personal__wrapper} key={name}>
                    <label htmlFor={name} className={styles.personal__label}>
                        {label}
                    </label>
                    <Input
                        name={name}
                        value={values[name]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={placeholder}
                        className={styles.personal__input}
                        type={type ? type : 'text'}
                        touched={touched}
                        errors={errors}
                        values={values}
                        required={true}
                    />
                </div>
            ))}
        </div>
    )
}

PersonalInfo.propTypes = {
    values: PropTypes.object,
    touched: PropTypes.object,
    errors: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
}

export default PersonalInfo
