import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'

import SvgSprite from '../../SvgSprite'

import * as styles from './styles.module.scss'

const RaisingInfo = props => {
    const { data, dueDate } = props
    const { due_date, funds_raised, funds_to_raise, time_to_sponsor, published_at } = data

    const today = moment()
    const endDate = moment(due_date, 'YYYY:MM:D')
    const daysLeft = endDate.diff(today._i, 'days')

    const start = new Date(published_at).getTime()
    const now = new Date().getTime()
    const end = new Date(due_date).getTime()
    const duration = Math.round(((now - start) / (end - start)) * 100)

    const raisingProgress = Math.round(
        ((funds_to_raise - (funds_to_raise - funds_raised)) / funds_to_raise) * 100
    )

    const wrapperClass = classNames(
        'fundraisingContainer',
        styles.wrapper,
        funds_to_raise && styles.withGoal
    )

    const timeClass = classNames(styles.hours, time_to_sponsor?.minutes > 0 && styles.minutes)

    return (
        <section className={styles.raising}>
            <div className={wrapperClass}>
                <div className={styles.date}>
                    <div className={styles.cardWrapper}>
                        <span className={styles.title}>Due Date</span>
                        <span className={styles.info}>{dueDate}</span>
                    </div>
                    <div className={styles.cardWrapper}>
                        <span className={styles.title}>Time left to show support</span>
                        <span className={styles.visualInfo}>
                            {daysLeft < 0 ? 0 : daysLeft} days to go
                        </span>
                        <div className={styles.visualContainer}>
                            <div
                                className={styles.inner}
                                style={{ width: `${duration > 100 ? 100 : duration}%` }}
                            />
                        </div>
                    </div>
                </div>
                {funds_to_raise ? (
                    <div className={styles.goal}>
                        <div className={styles.cardWrapper}>
                            <div>
                                <span className={styles.title}>Raised so far</span>
                                <span className={styles.info}>${funds_raised}</span>
                            </div>
                            <div>
                                <span className={styles.title}>Goal</span>
                                <span className={styles.info}>${funds_to_raise}</span>
                            </div>
                        </div>
                        <div className={styles.cardWrapper}>
                            <span className={styles.title}>Your progress</span>
                            <span className={styles.visualInfo}>
                                {raisingProgress}% already raised
                            </span>
                            <div className={styles.visualContainer}>
                                <div
                                    className={styles.inner}
                                    style={{
                                        width: `${raisingProgress > 100 ? 100 : raisingProgress}%`,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={styles.funds}>
                        <div className={styles.cardWrapper}>
                            <span className={styles.title}>Raised so far</span>
                            <span className={styles.info}>${funds_raised}</span>
                        </div>
                        <SvgSprite name="money" className={styles.icon} />
                    </div>
                )}
                <div className={timeClass}>
                    <div className={styles.cardWrapper}>
                        <span className={styles.title}>
                            Volunteered time
                            <br /> to support
                        </span>
                        <span className={styles.info}>
                            {' '}
                            {time_to_sponsor?.hours} {time_to_sponsor?.hours > 1 ? 'hours' : 'hour'}
                        </span>
                        {time_to_sponsor?.minutes > 0 && (
                            <span className={styles.info}>{time_to_sponsor?.minutes} minutes</span>
                        )}
                    </div>
                    <SvgSprite name="timer" className={styles.icon} />
                </div>
            </div>
        </section>
    )
}

RaisingInfo.propTypes = {
    data: PropTypes.object,
    dueDate: PropTypes.string,
}

export default RaisingInfo
