import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Accordion from '../elements/Accordion'
import ProtectedEmail from '../elements/ProtectedEmail'
import { individuals, сharities } from './data'
import { localEnv, devEnv } from '../../hostNames'

import * as styles from './styles.module.scss'
import { Link } from 'gatsby'

const FAQ = props => {
    const { FAQpage = false } = props
    const [activeBtn, setActiveBtn] = useState('Individuals')

    const sectionClass = classNames(styles.faq, FAQpage && styles.faqPage)
    const wrapperClass = classNames('container', styles.faq__wrapper)
    const linkClass = classNames(styles.faq__link, 'btn')

    const menuIndividualsClass = classNames(styles.faq__menuItem, {
        [styles.active]: activeBtn === 'Individuals',
    })
    const menuCharitiesClass = classNames(styles.faq__menuItem, {
        [styles.active]: activeBtn === 'Charities',
    })
    const individualsClass = classNames(styles.faq__questions, {
        [styles.active]: activeBtn === 'Individuals',
    })
    const charitiesClass = classNames(styles.faq__questions, {
        [styles.active]: activeBtn === 'Charities',
    })

    const getQuestions = questions => {
        const shortList = questions.filter((_, i) => i < 3)
        const questionList = FAQpage ? questions : shortList

        return questionList.map(({ title, content }, i) => (
            <Accordion title={title} key={i}>
                {content}
            </Accordion>
        ))
    }

    const screenWidth = typeof window !== 'undefined' && window.innerWidth

    return (
        <div>
            <section className={sectionClass}>
                {!FAQpage && <span id="faq" className="anchor" />}
                <div className={wrapperClass}>
                    {FAQpage && (
                        <h2 className={styles.faq__title}>
                            {screenWidth > 700 ? 'Frequently asked questions' : 'FAQ'}
                        </h2>
                    )}
                     {FAQpage && <div className={styles.faq__menu}>
                       <h3
                            className={menuIndividualsClass}
                            onClick={() => setActiveBtn('Individuals')}
                        >
                            Individuals
                        </h3>
                        {/* {(localEnv || devEnv) && (
                            <h3
                                className={menuCharitiesClass}
                                onClick={() => setActiveBtn('Charities')}
                            >
                                Charities
                            </h3>
                        )} */}
                    </div>}
                    <div className={styles.faq__content}>
                        {!FAQpage && <h2 className={styles.faq__title}>FAQ</h2>}
                        {/* <h3 className={styles.faq__subTitle}>Individuals</h3> */}
                        <div className={individualsClass}>{getQuestions(individuals)}</div>
                        {/* {(localEnv || devEnv) && (
                            <>
                                <h3 className={styles.faq__subTitle}>Charities</h3>
                                <div className={charitiesClass}>{getQuestions(сharities)}</div>
                            </>
                        )} */}
                        {FAQpage ? (
                            <div className={styles.faq__text}>
                                <h3>Still have questions?</h3>
                                <p>
                                    Do not hesitate to contact us!
                                </p>
                            </div>
                        ) : (
                            <Link to="/faq" className={linkClass}>
                                See all FAQs
                            </Link>
                        )}
                    </div>
                </div>
            </section>
        </div>
    )
}

FAQ.propTypes = {
    FAQpage: PropTypes.bool,
}

export default FAQ
