import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useFormik } from 'formik'

import AmountInfo from './AmountInfo'
import PersonalInfo from './PersonalInfo'
import PaymentInfo from './PaymentInfo/PaymentInfo'
import Spiner from '../../elements/Spiner'
import ModalTemplate from '../../ModalTemplate'
import Modal from '../../Modal/Modal.js'
import FeesMessage from './FeesMessage'

import { donationValidate } from './donationHelpers/donationValidate.js'
import donationSubmit from './donationHelpers/donationSubmit'

import * as styles from './styles.module.scss'

const DonationForm = props => {
    const { data } = props

    const [submitted, setSubmitted] = useState(false)
    const [show, setShow] = useState(false)
    const [cardFeeRate, setCardFeeRate] = useState(0)
    const [totalFees, setTotalFees] = useState(0)

    const charityId = data.nonprofit_designations?.find((_, index) => {
        return index === 0
    }).charity_id

    const initialValues = {
        firstname: '',
        lastname: '',
        email: '',
        amount: '',
        cardNum: '',
        expirationMonth: '',
        expirationYear: '',
        cvc: '',
        nameOnCard: '',
        sameAsPersonal: false,
        address: '',
        zip: '',
        country: '',
        state: '',
        city: '',
        privacy: false,
        coverFees: false,
        fees: 0,
    }

    const formik = useFormik({
        initialValues: initialValues,
        validate: donationValidate,
        onSubmit: values => {
            return values
        },
    })

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        handleReset,
        handleClick,
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
    } = formik

    const formSubmit = e => {
        e.preventDefault()
        handleSubmit()
        donationSubmit(values, data, setSubmitted, cardFeeRate, charityId, totalFees)
    }

    useEffect(() => {
        submitted === true && handleReset()
    }, [submitted])

    const containerClass = classNames(styles.donation__form, 'fundraisingContainer')
    const mobileScreen = window.innerWidth < 700

    return (
        <section className={styles.donation} id="donationForm">
            <h2 className={styles.donation__title}>Please consider making a donation:</h2>
            {/* Message should take up the full screen width on mobile devices */}
            {show && mobileScreen && <FeesMessage setShow={setShow} show={show} />}
            <form className={containerClass}>
                {/* Message should be positioned from container on desktop devices */}
                {show && !mobileScreen && <FeesMessage setShow={setShow} show={show} />}
                <div className={styles.donation__formBlock}>
                    <div>
                        <span className={styles.donation__step}>Step 1</span>
                        <h3>Personal information</h3>
                    </div>
                    <div className={styles.donation__formContent}>
                        <PersonalInfo
                            handleSubmit={handleSubmit}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            values={values}
                            errors={errors}
                            touched={touched}
                            setShow={setShow}
                        />
                    </div>
                </div>
                <div className={styles.donation__formBlock}>
                    <div>
                        <span className={styles.donation__step}>Step 2</span>
                        <h3>Payment information</h3>
                    </div>
                    <div className={styles.donation__formContent}>
                        <PaymentInfo
                            handleSubmit={handleSubmit}
                            handleChange={handleChange}
                            handleClick={handleClick}
                            handleBlur={handleBlur}
                            values={values}
                            errors={errors}
                            touched={touched}
                            submitted={submitted}
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                        />
                    </div>
                </div>
                <div className={styles.donation__formBlock}>
                    <div>
                        <span className={styles.donation__step}>Step 3</span>
                        <h3>Donation amount</h3>
                    </div>
                    <div className={styles.donation__formContent}>
                        <AmountInfo
                            handleChange={handleChange}
                            handleClick={handleClick}
                            values={values}
                            errors={errors}
                            touched={touched}
                            handleSubmit={formSubmit}
                            handleBlur={handleBlur}
                            setShow={setShow}
                            data={data}
                            totalFees={totalFees}
                            setCardFeeRate={setCardFeeRate}
                            setTotalFees={setTotalFees}
                            charityId={charityId}
                        />
                    </div>
                </div>
            </form>
            {submitted &&
                (submitted === 'progress' ? (
                    <Modal>
                        <div className={styles.donation__spiner}>
                            <Spiner />
                        </div>
                    </Modal>
                ) : (
                    <ModalTemplate
                        className={styles.donationModal}
                        closeModal={() => {
                            setSubmitted(false)
                        }}
                    >
                        <h3 className={styles.modalMessage}>
                            {submitted === 'error' ? (
                                'Error!'
                            ) : submitted === 'decline' ? (
                                <>
                                    <span>Error!</span>
                                    <span className={styles.decline}>
                                        Credit card has been declined, please verify the details
                                    </span>
                                </>
                            ) : (
                                'Thank you!'
                            )}
                        </h3>
                    </ModalTemplate>
                ))}
        </section>
    )
}

DonationForm.propTypes = {
    data: PropTypes.object,
}

export default DonationForm
