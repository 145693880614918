import React from 'react'
import PT from 'prop-types'

import 'src/styles/global.scss'
import { SpriteLoader } from 'src/components/SvgSprite'
import Preloader from '../Preloader'
import Header from '../Header/'
import Footer from '../Footer/'

import * as style from './style.module.scss'

const CommonImports = props => {
    const { header = true, children } = props
    return (
        <div className={style.page}>
            {header && <Header />}
            <SpriteLoader />
            {children}
            <Preloader />
            <Footer />
        </div>
    )
}

CommonImports.propTypes = {
    header: PT.bool,
    children: PT.any,
}

export default CommonImports
