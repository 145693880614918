// extracted by mini-css-extract-plugin
export var GYWAllowsYouTo = "styles-module--GYWAllowsYouTo--b3zsd";
export var cardWrapper = "styles-module--cardWrapper--ePj91";
export var wrappeer = "styles-module--wrappeer--jdWUE";
export var header = "styles-module--header--XfOoj";
export var appImg = "styles-module--appImg--JXkhJ";
export var textApp = "styles-module--textApp--z77Gq";
export var title = "styles-module--title--BqZ3G";
export var subTitle = "styles-module--subTitle--miaf7";
export var text = "styles-module--text--VkDvP";
export var noWrap = "styles-module--noWrap---0B6F";
export var br = "styles-module--br--J2av0";
export var bodyGrid = "styles-module--bodyGrid--r9cjp";
export var card = "styles-module--card--nNT8m";
export var card0 = "styles-module--card0--kBcvW";
export var card1 = "styles-module--card1--+wMGP";
export var card2 = "styles-module--card2--MzKHH";
export var card3 = "styles-module--card3--KvVdD";
export var card4 = "styles-module--card4--RCOWz";
export var card5 = "styles-module--card5--EJm96";
export var card6 = "styles-module--card6--Rb+Dk";
export var share = "styles-module--share--sNWpF";
export var btnImg = "styles-module--btnImg--nEN5r";
export var downloadAppWrapper = "styles-module--downloadAppWrapper--0nSp9";
export var downloadAppTitle = "styles-module--downloadAppTitle--KzcOz";
export var downloadApp = "styles-module--downloadApp--Z0x8k";
export var btnDownloadApp = "styles-module--btnDownloadApp--LihKL";
export var cardsList = "styles-module--cardsList--+d1-j";