import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import BaseContainer from '../components/BaseContainer'

import * as styles from '../styles/404Styles/styles.module.scss'

const NotFoundPage = () => {
    return (
        <BaseContainer>
            <main className={styles.pageStyles}>
                <div className="container">
                    <div className={styles.img}>
                        <StaticImage
                            src={'../images/optimized/png/404.png'}
                            formats={['AUTO', 'WEBP', 'AVIF']}
                            alt="404"
                            layout="constrained"
                            placeholder="tracedSVG"
                        />
                    </div>
                    <div className={styles.textStyle}>
                        <h1 className={styles.title}>404</h1>
                        <p className={styles.subTitle}>Something went wrong</p>
                        <Link to="/" className={styles.btnReturn}>
                            Return to main page
                        </Link>
                    </div>
                </div>
            </main>
        </BaseContainer>
    )
}

export default NotFoundPage
