import React from 'react'
import classNames from 'classnames'
import * as styles from './styles.module.scss'

const Spiner = () => {
    const loadingClass = classNames('fundraisingContainer', styles.loading)
    return (
        <div className={loadingClass}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default Spiner
