import React from 'react'
import PropTypes from 'prop-types'

import SvgSprite from '../../../SvgSprite'
import * as styles from './styles.module.scss'

const FeesMessage = props => {
    const { setShow } = props
    return (
        <>
            <div className={styles.feesInfo}>
                <span
                    className={styles.feesInfo__button}
                    onClick={() => {
                        setShow(false)
                    }}
                >
                    <SvgSprite name="close" />
                </span>
                <div className={styles.feesInfo__wrapper}>
                    <span className={styles.triangle} />
                    <span className={styles.feesInfo__title}>Good Your Way, Inc. Fee: 1.75%</span>
                    <p>Fees external to Good Your Way, Inc.:</p>
                    <ul>
                        <li>Payment processing and distribution fee: 3.0% </li>
                        <li>
                            Credit card fees: a sum equal to 2.2% and $0.30 per transaction for
                            Visa, MasterCard and Discover, and 3.5% and $0.30 per transaction for
                            American Express.
                        </li>
                    </ul>
                    <p> For more details please refer to our Terms Of Use.</p>
                </div>
            </div>
        </>
    )
}

FeesMessage.propTypes = {
    setShow: PropTypes.func,
}

export default FeesMessage
