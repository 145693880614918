// extracted by mini-css-extract-plugin
export var faq = "styles-module--faq--KDa7Y";
export var faq__title = "styles-module--faq__title--rf2+5";
export var faq__menu = "styles-module--faq__menu--ueAXK";
export var faq__menuItem = "styles-module--faq__menuItem--FOpGJ";
export var active = "styles-module--active--RCQZi";
export var faq__questions = "styles-module--faq__questions--kGJFG";
export var faq__link = "styles-module--faq__link--T7M05";
export var faq__subTitle = "styles-module--faq__subTitle--pJiBN";
export var faq__wrapper = "styles-module--faq__wrapper--hmn4I";
export var faq__content = "styles-module--faq__content--DdPL+";
export var faqPage = "styles-module--faqPage--bao7m";
export var faq__text = "styles-module--faq__text--pBqNj";