import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import axios from 'axios'
import { StaticImage } from 'gatsby-plugin-image'

import SvgSprite from '../SvgSprite'
import Spiner from '../elements/Spiner'

import * as styles from './styles.module.scss'

const apiUrl = `https://${process.env.API_HOST}/api/v1`

const Unsubscribe = props => {
    const { emailInfo } = props
    const unsubscribe = '../../images/optimized/png/unsubscribe.png'
    const [userUnsubscribed, setUserUnsubscribed] = useState(false)

    const emailStart = emailInfo.indexOf('email=') + 6
    const emailEnd = emailInfo.indexOf('&token')
    const tokenStart = emailInfo.indexOf('&token=') + 7
    const email = emailInfo.slice(emailStart, emailEnd)
    const token = emailInfo.slice(tokenStart, emailInfo.length)

    const sendUnsubscribeRequest = e => {
        e?.prevent.default()
        setUserUnsubscribed('progress')

        return axios
            .put(`${apiUrl}/notifications/email/unsubscribe`, {
                user_email: email,
                user_token: token,
            })
            .then(() => setUserUnsubscribed(true))
            .catch(() => setUserUnsubscribed('error'))
    }

    const sectionClass = classNames('container', styles.unsubscribe)

    return (
        <section className={sectionClass}>
            <SvgSprite name="logo" className={styles.unsubscribe__logo} />
            {(() => {
                switch (userUnsubscribed) {
                    case false:
                        return (
                            <>
                                <StaticImage src={unsubscribe}
                                className={styles.unsubscribe__img}
                                />
                                <h1 className={styles.unsubscribe__title}>
                                    Are you sure <br /> you want to Unsubscribe from all email
                                    notifications?
                                </h1>
                                <span className={styles.unsubscribe__message}>
                                    Regardless of your selection, we may still have to send you
                                    critical notifications (e.g., donation receipts).
                                </span>
                                <button
                                    className={styles.unsubscribe__btn}
                                    type="submit"
                                    onClick={() => sendUnsubscribeRequest()}
                                >
                                    Yes, Unsubscribe
                                </button>
                            </>
                        )
                    case true:
                        return (
                            <h1 className={styles.unsubscribe__title}>
                                You&apos;ve successfully unsubscribed!
                            </h1>
                        )
                    case 'error':
                        return <h1 className={styles.unsubscribe__title}>Invalid Request!</h1>
                    case 'progress':
                        return (
                            <div>
                                <Spiner />
                            </div>
                        )
                }
            })()}
        </section>
    )
}

Unsubscribe.propTypes = {
    emailInfo: PropTypes.string,
}

export default Unsubscribe
