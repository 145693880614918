import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Meta from 'src/components/Meta'
import ogGYW from '../../images/optimized/png/page-preview.png'

import getFundraisingData from '../../utils/fetchData'
import SvgSprite from '../SvgSprite'
import User from './User'
import RaisingInfo from './RaisingInfo'
import Organizations from './Organizations'
import DonationForm from './DonationForm'
import Activity from './Activity'
import Spiner from '../elements/Spiner'

import * as styles from './styles.module.scss'

const FundraisingPage = props => {
    const { goalId } = props
    const [userInfo, setUserInfo] = useState({})
    const [loading, setLoading] = useState(true)
    const [showForm, setShowForm] = useState(false)
    const [dueDate, setDueDate] = useState('')

    useEffect(() => {
        getFundraisingData(goalId).then(result => {
            setUserInfo(result?.data)
        })
    }, [])

    useEffect(() => {
        if (Object.keys(userInfo).length > 0) {
            setLoading(false)
            const dataArray = userInfo?.due_date?.split('-')
            setDueDate(`${dataArray[1]}/${dataArray[2]}/${dataArray[0]}`)
        }
    }, [userInfo])

    const metaData = {
        title: `Good Your Way - ${
            userInfo.first_name ? userInfo.first_name + "'s" : ''
        } fundraising ${dueDate}`,
        description:
            'Many people and organizations are dedicated to better societies. You want to help, in the way that works for you. We support, give recognition, and amplify your impact. Lead by example and make it count.',
        url: `https://www.goodyourway.com/fundraising/${goalId}`,
        img: `${ogGYW}`,
        keywords: 'fundraising, charity',
    }

    const headerClass = classNames('fundraisingContainer', styles.header)

    return (
        <>
            <Meta content={metaData} />
            <header className={headerClass}>
                <SvgSprite name="logo" className={styles.logo} />
                <SvgSprite name="logoBlue" className={styles.logoBlue} />
            </header>
            {!loading ? (
                <main className={styles.fundraisingMain}>
                    <User data={userInfo} setShowForm={setShowForm} />
                    <RaisingInfo data={userInfo} dueDate={dueDate} />
                    <Organizations data={userInfo} setShowForm={setShowForm} />
                    {userInfo.volunteered_nonprofits.length !== 0 && <Activity data={userInfo} />}
                    {showForm && userInfo.status !== 'BLOCKED' && <DonationForm data={userInfo} />}
                </main>
            ) : (
                <Spiner />
            )}
        </>
    )
}

FundraisingPage.propTypes = {
    goalId: PropTypes.string,
}

export default FundraisingPage
