import React, { useState, useRef } from 'react'
import PT from 'prop-types'
import classNames from 'classnames'

import * as styles from './styles.module.scss'

const Accordion = props => {
    const { children, title } = props
    const [open, setOpen] = useState(false)
    const [height, setHeight] = useState('0px')
    const content = useRef(null)

    const toggleAccordion = () => {
        setOpen(!open)
        setHeight(!open ? `${content.current.scrollHeight}px` : '0px')
    }

    const containerClass = classNames(styles.accordion, {
        [styles.open]: open,
    })

    const titleClass = classNames(styles.accordion__title, {
        [styles.open]: open,
    })
    const contentClass = classNames(styles.accordion__content, {
        [styles.open]: open,
    })

    return (
        <div className={containerClass} onClick={toggleAccordion}>
            <span className={titleClass}>{title}</span>
            <p ref={content} style={{ maxHeight: `${height}` }} className={contentClass}>
                {children}
            </p>
        </div>
    )
}

Accordion.propTypes = {
    children: PT.any.isRequired,
    title: PT.string.isRequired,
}

export default Accordion
