import React from 'react'
import classNames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'

import Card from '../Card'
import DownloadBtns from '../DownloadBtns'

import * as styles from './styles.module.scss'

const card0 = '../../images/optimized/png/sections/GYWAllowsYouTo/card0.png'
const card1 = '../../images/optimized/png/sections/GYWAllowsYouTo/card1.png'
const card2 = '../../images/optimized/png/sections/GYWAllowsYouTo/card2.png'
const card3 = '../../images/optimized/png/sections/GYWAllowsYouTo/card3.png'
const card4 = '../../images/optimized/png/sections/GYWAllowsYouTo/card4.png'
const share = '../../images/optimized/png/sections/GYWAllowsYouTo/Share.png'
const card5 = '../../images/optimized/png/sections/GYWAllowsYouTo/confirmed.png'
const card6 = '../../images/optimized/png/sections/GYWAllowsYouTo/donation.png'

const appMobile = '../../images/optimized/png/sections/GYWAllowsYouTo/appMobile.png'

const classNamesCard1 = classNames(styles.card, styles.card1)
const classNamesCard2 = classNames(styles.card, styles.card2)
const classNamesCard3 = classNames(styles.card, styles.card3)
const classNamesCard4 = classNames(styles.card, styles.card4)
const classNamesCard5 = classNames(styles.card, styles.card5)
const classNamesCard6 = classNames(styles.card, styles.card6)

const data = [
    {
        img: (
            <div>
                <StaticImage
                    src={card0}
                    formats={['AUTO', 'WEBP', 'AVIF']}
                    alt="Screenshot of the part of the app where you create a track record of your free work and volunteering"
                    layout="constrained"
                    className={styles.card0}
                    placeholder="tracedSVG"
                />
            </div>
        ),
        title: 'Attract volunteering activity by charitable giving',
        text: 'Through donations you can now show support and encourage individuals to volunteer.',
    },
    {
        img: (
            <div>
                <StaticImage
                    src={card1}
                    formats={['AUTO', 'WEBP', 'AVIF']}
                    alt="Screenshot of the part of the app where you create a track record of your free work and volunteering"
                    layout="constrained"
                    className={classNamesCard1}
                />
                <StaticImage
                    src={card2}
                    formats={['AUTO', 'WEBP', 'AVIF']}
                    alt="Screenshot of the part of the app where you create a track record of your free work and volunteering"
                    layout="constrained"
                    className={classNamesCard2}
                />
            </div>
        ),
        title: 'Build a track record of your pro bono work and volunteering',
        text:
            'You can now record and make public the track record of your volunteering experience.',
    },
    {
        img: (
            <div>
                <StaticImage
                    src={card3}
                    formats={['AUTO', 'WEBP', 'AVIF']}
                    alt="screenshot of the part of the app for managing all your volunteer activities in one place"
                    layout="constrained"
                    className={classNamesCard3}
                />
                <StaticImage
                    src={card4}
                    formats={['AUTO', 'WEBP', 'AVIF']}
                    alt="screenshot of the part of the app for managing all your volunteer activities in one place"
                    layout="constrained"
                    className={classNamesCard4}
                />
            </div>
        ),
        title: 'Manage all your volunteering activity in one place',
        text:
            'Set goals, schedule events, keep track and review your personal volunteering activity.',
    },
    {
        img: (
            <>
                <StaticImage
                    width={162}
                    height={147}
                    src={share}
                    formats={['AUTO', 'WEBP', 'AVIF']}
                    alt="Megafon image"
                    layout="fixed"
                    className={styles.share}
                    placeholder="tracedSVG"
                />
                <div>
                    <span className={styles.btnImg}>Share</span>
                    <span className={styles.btnImg}>Close</span>
                </div>
            </>
        ),
        title: 'Endorse specific charities and social causes that resonate with you',
        text:
            'Bring the spotlight on lesser known charitable organizations, that make a big difference.',
    },
    {
        img: (
            <div>
                <StaticImage
                    src={card5}
                    formats={['AUTO', 'WEBP', 'AVIF']}
                    alt="Screenshot of the part of the app in which other participants of the volunteer activity see and are inspired by your actions"
                    layout="constrained"
                    className={classNamesCard5}
                />
                <StaticImage
                    src={card6}
                    formats={['AUTO', 'WEBP', 'AVIF']}
                    alt="Screenshot of the part of the app in which other participants of the volunteer activity see and are inspired by your actions"
                    layout="constrained"
                    className={classNamesCard6}
                />
            </div>
        ),
        title: 'Let your actions encourage others',
        text:
            'Share your activity with others. Invite friends, family and colleagues to participate, in a way that works for them.',
    },
]

const GYWAllowsYouTo = () => {
    const wrapperClassNames = classNames('container', styles.wrappeer)
    const listClass = classNames(styles.bodyGrid, styles.cardsList)
    return (
        <section className={styles.GYWAllowsYouTo}>
            <div className={wrapperClassNames}>
                <div className={styles.header}>
                    <StaticImage
                        src={appMobile}
                        formats={['AUTO', 'WEBP', 'AVIF']}
                        alt="screenshoot app GYW-volunteers"
                        layout="constrained"
                        className={styles.appImg}
                        placeholder="blurred"
                    />
                    <div className={styles.textApp}>
                        <h2 className={styles.title}>GYW allows you to:</h2>
                        <p className={styles.subTitle}>
                            Attract financial support{' '}
                            <span className={styles.noWrap}>
                                {' '}
                                to charities <br className={styles.br} /> by volunteering
                            </span>
                        </p>
                        <p className={styles.text}>
                            The time you volunteer can now attract funding to multiple charities of
                            your choice.
                        </p>
                    </div>
                </div>

                <ul className={listClass}>
                    {data.map(({ title, text, img }) => (
                        <li key={title} className={styles.cardWrapper}>
                            <Card title={title} text={text} className="allowsCard">
                                {img}
                            </Card>
                        </li>
                    ))}
                    <li className={styles.downloadAppWrapper}>
                        <h3 className={styles.downloadAppTitle}>
                            Get the app <br /> from the Apple Store{' '}
                            <span className={styles.noWrap}>or Google Play</span>
                        </h3>
                        <div className={styles.downloadApp}>
                            <DownloadBtns showQr={true} />
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}

export default GYWAllowsYouTo
