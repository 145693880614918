import axios from 'axios'

const apiUrl = `https://${process.env.API_HOST}/api`

const getFundraisingData = goalId => {
    const url = `${apiUrl}/v1/fundraising/goals/${goalId}/published`
    return axios
        .get(url)
        .then(resp => resp)
        .catch(() => {
            return (location.pathname = '/404')
        })
}

export default getFundraisingData
