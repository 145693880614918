import React from 'react'
import classNames from 'classnames'

import DownloadBtns from '../DownloadBtns/DownloadBtns'

import * as styles from './styles.module.scss'

const DownloadApp = () => {
    return (
        <section className={styles.app}>
            <span id="download-app" className="anchor" />
            <div className="container">
                <div className={styles.app__wrapper}>
                    <div>
                        <h2 className={styles.app__title}>
                            Download <br /> Our App
                        </h2>
                        <p className={styles.app__text}>
                            Lead by example.
                            <br /> Make it count.
                            <br /> Amplify your impact.
                        </p>
                    </div>

                    <div className={styles.app__buttonsWrapper}>
                        <DownloadBtns className={styles.app__button} showQr={true} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DownloadApp
