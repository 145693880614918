export const formValidate = values => {
    const errors = {}
    const { firstname, lastname, email, message, data } = values
    if (!firstname) {
        errors.firstname = 'Required'
    } else if (firstname.length > 15) {
        errors.firstName = 'Must be 15 characters or less'
    }
    if (lastname?.length > 20) {
        errors.lastname = 'Must be 20 characters or less'
    }
    if (!email) {
        errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }
    if (!message) {
        errors.message = 'Required'
    }
    if (data === false) {
        errors.data = 'Required'
    }

    return errors
}
