export const dataForLists = {
    volunteers: [
        {
            text: 'Designate charities you want to support.',
            link: {
                href: 'https://youtu.be/nSUOWrSsU7M',
                name: 'Watch a video tutorial',
            },
        },

        {
            text: 'Go volunteer, and record your actions.',
            link: {
                href: 'https://youtu.be/W0JRVSDIsMQ',
                name: 'Watch a video tutorial',
            },
        },

        {
            text: 'Convert your actions into a personalized fundraising webpage.',
            link: {
                href: 'https://youtu.be/OuBIvi1i2sU',
                name: 'Watch a video tutorial',
            },
        },
        {
            text: 'Set goals, share activity and generate reports.',
            link: {
                href: 'https://youtube.com/shorts/5WL-EWhjCgY?feature=share',
                name: 'Watch a video tutorial',
            },
        },
    ],

    donors: [
        {
            text:
                'Whether you are an individual or an organization, you can now easily support volunteering by matching charitable donations.',
        },
        {
            text:
                'No need to download the application or register with GYW, simply follow personalized fundraising webpage of a GYW volunteer.',
            link: {
                href: 'https://youtu.be/AY8MsCo-hjc',
                name: 'Watch a video tutorial',
            },
        },
    ],

    charities: [
        'As a registered 501(c)(3) charitable organization, there is nothing you have to do.',
        'There are no licensing costs and no registration process you have to go through to benefit from GYW members support.',
        'If you find the concept useful, you can choose to mention GYW to your community.',
        'We welcome you to contact us with any questions or requests.',
    ],
}
