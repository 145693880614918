import React from 'react'
import * as styles from './styles.module.scss'

const DonationProcessing = () => {
    return (
        <section className={styles.donationProcessing}>
            <div className="container">
                <h2 className={styles.title}>Donation processing</h2>
                <div className={styles.textWrapper}>
                    <p className={styles.text}>
                        To manage donation processing and disbursement Good Your Way, Inc. partners
                        with FrontStream Holdings, LLC - industry leader in secure payment
                        technology and charitable donation processing and distribution services.
                    </p>
                    <p className={styles.text}>
                        For further details please refer to the “Donations” section of our Terms of
                        Use and to{' '}
                        <a
                            href={'https://www.frontstreampayments.com/'}
                            target="_blank"
                            rel="noopener nonreferrer"
                        >
                            https://www.frontstreampayments.com
                        </a>
                    </p>
                </div>
            </div>
        </section>
    )
}

export default DonationProcessing
