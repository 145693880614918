import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import Select, { components } from 'react-select'

import USA from '../../../images/optimized/png/flags/USA.png'
import './Dropdown.scss'

const flagsArray = [USA]

const Option = props => {
    const { options, label, data } = props
    const index = options.indexOf(data)
    return (
        <components.Option {...props}>
            <div className="option">
                <img src={flagsArray[index]} alt="Country flag" className="option__img" />
                <span className="option__label">{label}</span>
            </div>
        </components.Option>
    )
}

const Dropdown = props => {
    const { name, values, options, touched, errors, disabled, required, onBlur, submitted } = props
    const select = useRef(null)

    useEffect(() => {
        submitted === true && select.current.clearValue()
    }, [submitted])

    const customOption = name === 'country' ? { Option } : null

    return (
        <>
            <Select
                inputId={name}
                ref={select}
                components={customOption}
                className="select"
                classNamePrefix="select"
                name={name}
                touched={touched}
                errors={errors}
                onChange={opt => {
                    values[name] = opt?.value
                    select.current.blur()
                }}
                onBlur={onBlur}
                isDisabled={disabled}
                options={options}
                placeholder=""
                required={required}
            />
        </>
    )
}

Option.propTypes = {
    options: PropTypes.array,
    label: PropTypes.string,
    data: PropTypes.object,
}

Dropdown.propTypes = {
    name: PropTypes.string,
    values: PropTypes.object,
    options: PropTypes.array,
    touched: PropTypes.object,
    errors: PropTypes.object,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    onBlur: PropTypes.func,
    submitted: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default Dropdown
