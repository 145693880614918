// extracted by mini-css-extract-plugin
export var burger = "styles-module--burger--8Q3wF";
export var strip = "styles-module--strip--D+6VP";
export var logo = "styles-module--logo--TNO3g";
export var getApp = "styles-module--getApp--9adeF";
export var app = "styles-module--app--o7t9A";
export var open = "styles-module--open--lH+ch";
export var nav = "styles-module--nav--Ucd1y";
export var list = "styles-module--list--TZikv";
export var item = "styles-module--item--gb64g";
export var link = "styles-module--link--Xh0p6";
export var navFooter = "styles-module--navFooter--QZErX";