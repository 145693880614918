import React from 'react'
import PropTypes from 'prop-types'

import NO_POVERTY from '../../../images/optimized/png/sections/activityIcons/1.png'
import ZERO_HUNGER from '../../../images/optimized/png/sections/activityIcons/2.png'
import GOOD_HEALTH from '../../../images/optimized/png/sections/activityIcons/3.png'
import QUALITY_EDUCATION from '../../../images/optimized/png/sections/activityIcons/4.png'
import GENDER_EQUALITY from '../../../images/optimized/png/sections/activityIcons/5.png'
import CLEAN_WATER from '../../../images/optimized/png/sections/activityIcons/6.png'
import CLEAN_ENERGY from '../../../images/optimized/png/sections/activityIcons/7.png'
import ECONOMIC_GROWTH from '../../../images/optimized/png/sections/activityIcons/8.png'
import INDUSTRY from '../../../images/optimized/png/sections/activityIcons/9.png'
import REDUCED_INEQUALITIES from '../../../images/optimized/png/sections/activityIcons/10.png'
import SUSTAINABLE_CITIES from '../../../images/optimized/png/sections/activityIcons/11.png'
import RESPONSIDLE_CONSUMPTION from '../../../images/optimized/png/sections/activityIcons/12.png'
import CLIMATE_ACTION from '../../../images/optimized/png/sections/activityIcons/13.png'
import LIFE_BELOW_WATER from '../../../images/optimized/png/sections/activityIcons/14.png'
import LIFE_ON_LAND from '../../../images/optimized/png/sections/activityIcons/15.png'
import PEACE_JUSTICE from '../../../images/optimized/png/sections/activityIcons/16.png'
import PARTNERSHIP from '../../../images/optimized/png/sections/activityIcons/17.png'
import SELF_ORGANIZED from '../../../images/optimized/png/dog.png'
import * as styles from './styles.module.scss'

const Activity = props => {
    const { data } = props
    const { volunteered_nonprofits } = data

    const iconsArray = [
        NO_POVERTY,
        ZERO_HUNGER,
        GOOD_HEALTH,
        QUALITY_EDUCATION,
        GENDER_EQUALITY,
        CLEAN_WATER,
        CLEAN_ENERGY,
        ECONOMIC_GROWTH,
        INDUSTRY,
        REDUCED_INEQUALITIES,
        SUSTAINABLE_CITIES,
        RESPONSIDLE_CONSUMPTION,
        CLIMATE_ACTION,
        LIFE_BELOW_WATER,
        LIFE_ON_LAND,
        PEACE_JUSTICE,
        PARTNERSHIP,
        SELF_ORGANIZED,
    ]

    return (
        <section className={styles.activity}>
            <div className="fundraisingContainer">
                <h2 className={styles.activity__title}>
                    Support my efforts and help make a difference.
                    <span>Here is what I did:</span>
                </h2>
                <ul>
                    {volunteered_nonprofits.map(
                        ({ nonprofit_causes, organization_name, volunteered_time }) => (
                            <li key={organization_name} className={styles.activity__item}>
                                <a
                                    className={styles.activity__icon}
                                    href="https://sdgs.un.org/goals"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {Object.keys(nonprofit_causes).map((id, index) => {
                                        const imgClass =
                                            Object.keys(nonprofit_causes).length < 5
                                                ? styles.activity__goalSmall
                                                : styles.activity__goalLarge

                                        return (
                                            <img
                                                key={id + index}
                                                src={iconsArray[id - 1]}
                                                alt="support organization"
                                                className={imgClass}
                                                data-name={id === '18' ? 'self_organized' : ''}
                                            />
                                        )
                                    })}
                                </a>
                                <div className={styles.textContainer}>
                                    <span className={styles.name}>{organization_name}</span>
                                    <ul className={styles.goalList}>
                                        {Object.values(nonprofit_causes).map((goal, index) => (
                                            <li key={goal + index} className={styles.goal}>
                                                {goal}
                                            </li>
                                        ))}
                                    </ul>
                                    <span className={styles.activity__hours}>
                                        {volunteered_time.hours} h{' '}
                                        {volunteered_time.minutes > 0 &&
                                            `${volunteered_time.minutes} min`}{' '}
                                        volunteered
                                    </span>
                                </div>
                            </li>
                        )
                    )}
                </ul>
            </div>
        </section>
    )
}

Activity.propTypes = {
    data: PropTypes.object,
}

export default Activity
