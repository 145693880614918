import React from 'react'

import Form from '../Form'
import { formValidate } from './formValidate'

import * as styles from './styles.module.scss'

const ContactUs = () => {
    const formValues = {
        firstname: '',
        lastname: '',
        email: '',
        message: '',
        data: false,
        subscribed: false,
    }

    return (
        <section className={styles.ContactUs}>
            <span id="contact-us" className="anchor" />
            <div className="container">
                <h2 className={styles.title}>Contact Us</h2>
                <div className={styles.wrapper}>
                    <div className={styles.formWrapper} id="contactForm">
                        <Form initialValues={formValues} validate={formValidate} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactUs
