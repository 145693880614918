import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import SupportButton from '../SupportButton/SupportButton'
import SvgSprite from '../../SvgSprite'

import * as styles from './styles.module.scss'

const Organizations = props => {
    const { data, setShowForm } = props

    const { first_name, nonprofit_designations } = data
    const messageClass = classNames('fundraisingContainer', styles.message)

    return (
        <section className={styles.organizations}>
            <div className="fundraisingContainer">
                <h2 className={styles.organizations__title}>
                    Through my volunteering activity I aim to raise funds to support the following
                    charitable organizations:
                </h2>
            </div>
            {nonprofit_designations.length !== 0 ? (
                <>
                    <div
                        className={styles.organizations__mapMobile}
                        data-quantity={nonprofit_designations.length}
                    >
                        {nonprofit_designations[0] && (
                            <div className={styles.orgBlock1}>
                                <span className={styles.orgName}>
                                    {nonprofit_designations[0].organization_name}
                                </span>
                                <span className={styles.percentage}>
                                    {nonprofit_designations[0].percentage}%
                                </span>
                                <SvgSprite name="building1" className={styles.bld1} />
                                <SvgSprite name="road1" className={styles.road1} />
                            </div>
                        )}
                        {nonprofit_designations[1] && (
                            <div className={styles.orgBlock2}>
                                <span className={styles.orgName}>
                                    {nonprofit_designations[1].organization_name}
                                </span>
                                <span className={styles.percentage}>
                                    {nonprofit_designations[1].percentage}%
                                </span>
                                <SvgSprite name="building2" className={styles.bld2} />
                            </div>
                        )}
                        {nonprofit_designations[2] && (
                            <div className={styles.orgBlock3}>
                                <span className={styles.orgName}>
                                    {nonprofit_designations[2].organization_name}
                                </span>
                                <span className={styles.percentage}>
                                    {nonprofit_designations[2].percentage}%
                                </span>
                                <SvgSprite name="building3" className={styles.bld3} />
                                <SvgSprite name="road2" className={styles.road2} />
                            </div>
                        )}
                        {nonprofit_designations[3] && (
                            <div className={styles.orgBlock4}>
                                <span className={styles.orgName}>
                                    {nonprofit_designations[3].organization_name}
                                </span>
                                <span className={styles.percentage}>
                                    {nonprofit_designations[3].percentage}%
                                </span>
                                <SvgSprite name="building4" className={styles.bld4} />
                                <SvgSprite name="tree" className={styles.tree} />
                            </div>
                        )}
                        {nonprofit_designations[4] && (
                            <div className={styles.orgBlock5}>
                                <span className={styles.orgName}>
                                    {nonprofit_designations[4].organization_name}
                                </span>
                                <span className={styles.percentage}>
                                    {nonprofit_designations[4].percentage}%
                                </span>
                                <SvgSprite name="building5" className={styles.bld5} />
                                <SvgSprite name="road3" className={styles.road3} />
                            </div>
                        )}
                        <SvgSprite name="man" className={styles.man} />
                    </div>
                    <div
                        className={styles.organizations__mapFull}
                        data-quantity={nonprofit_designations.length}
                    >
                        <SvgSprite name="allOrganizations" className={styles.orgAll} />
                        <ul>
                            {nonprofit_designations.map(({ organization_name, percentage }) => (
                                <li className={styles.orgInfo} key={organization_name}>
                                    <span className={styles.orgName}>{organization_name}</span>
                                    <span className={styles.percentage}>{percentage}%</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </>
            ) : (
                <p className={messageClass}>
                    <span>
                        We got notified that designated charities cannot currently receive
                        charitable contributions.
                    </span>
                    <span>
                        We have notified <span className={styles.message__name}> {first_name}</span>{' '}
                        to adjust their current fundraising designations as soon as possible.
                    </span>
                    <span>
                        {' '}
                        You are welcome to let{' '}
                        <span className={styles.message__name}> {first_name}</span> know that you
                        would like to support them, as soon as they update their designated
                        charities.
                    </span>
                </p>
            )}
            <SupportButton setShowForm={setShowForm} data={data} />
        </section>
    )
}

Organizations.propTypes = {
    data: PropTypes.object,
    setShowForm: PropTypes.func,
}

export default Organizations
