import { formatAmount } from './index.js'
const requiredError = 'Required'

export const donationValidate = values => {
    const errors = {}
    const {
        firstname,
        lastname,
        email,
        amount,
        cardNum,
        expirationMonth,
        expirationYear,
        cvc,
        nameOnCard,
        address,
        zip,
        country,
        state,
        city,
        privacy,
    } = values

    if (!firstname.trim()) {
        errors.firstname = requiredError
    } else if (firstname.length > 50) {
        errors.firstname = 'Must be 50 characters or less'
    } else if (!/^[a-z ]+$/i.test(values.firstname)) {
        errors.firstname = 'Letters and spaces only'
    }

    if (!lastname.trim()) {
        errors.lastname = requiredError
    } else if (lastname.length > 50) {
        errors.lastname = 'Must be 50 characters or less'
    } else if (!/^[a-z ]+$/i.test(values.lastname)) {
        errors.lastname = 'Letters and spaces only'
    }

    if (!email) {
        errors.email = requiredError
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email.trim())) {
        errors.email = 'Invalid email address'
    }

    if (!amount) {
        errors.amount = requiredError
    } else if (amount < 10) {
        errors.amount = 'Amount must be greater than $9.99'
    }

    if (!cardNum) {
        errors.cardNum = requiredError
    } else if (
        !/^[0-9 ]+$/i.test(values.cardNum) ||
        cardNum.split(' ').join('').length > 16 ||
        cardNum.split(' ').join('').length < 15
    ) {
        errors.cardNum = 'Invalid card number'
    }

    if (!expirationMonth || !expirationYear) {
        errors.expiration = requiredError
    } else if (!/^\d+$/i.test(expirationMonth) || !/^\d+$/i.test(expirationYear)) {
        errors.expiration = 'Numbers only'
    } else if (expirationMonth.length < 2 || expirationYear.length < 2) {
        errors.expiration = 'Invalid format. Must be MM/YY'
    } else if (expirationMonth > 12) {
        errors.expiration = 'Invalid month'
    } else {
        const nowDate = new Date()
        const currentMonth = nowDate.getMonth() + 1
        const currentYear = nowDate.getFullYear().toString().split('')
        if (`${currentYear[2]}${currentYear[3]}` > expirationYear) {
            errors.expiration = 'Сard expired'
        } else if (`${currentYear[2]}${currentYear[3]}` === expirationYear) {
            if (currentMonth > expirationMonth) {
                errors.expiration = 'Сard expired'
            }
        }
    }

    if (!cvc.trim()) {
        errors.cvc = requiredError
    } else if (cvc.length > 4 || cvc.length < 3) {
        errors.cvc = 'Invalid cvc'
    }

    if (!nameOnCard.trim()) {
        errors.nameOnCard = requiredError
    } else if (nameOnCard.length > 25) {
        errors.nameOnCard = 'Must be 25 characters or less'
    }

    if (!address.trim()) {
        errors.address = requiredError
    } else if (address.length > 50) {
        errors.address = 'Must be 50 characters or less'
    }

    if (!zip) {
        errors.zip = requiredError
    } else if (zip.length > 10) {
        errors.zip = 'Must be 10 characters or less'
    } else if (!/^[0-9-]+$/i.test(values.zip)) {
        errors.zip = 'Only numbers and hyphen'
    }

    if (!country) {
        errors.country = requiredError
    }

    if (!state) {
        errors.state = requiredError
    }

    if (!city.trim()) {
        errors.city = requiredError
    } else if (city.length > 50) {
        errors.city = 'Must be 50 characters or less'
    }

    if (!privacy) {
        errors.privacy = requiredError
    }

    return errors
}
