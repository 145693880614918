// extracted by mini-css-extract-plugin
export var organizations = "styles-module--organizations--u7H0I";
export var organizations__title = "styles-module--organizations__title--Lb-rt";
export var percentage = "styles-module--percentage--YtaRK";
export var orgName = "styles-module--orgName--Yqqqg";
export var message = "styles-module--message--qyOSB";
export var message__name = "styles-module--message__name---txMu";
export var organizations__mapMobile = "styles-module--organizations__mapMobile--xOsx8";
export var man = "styles-module--man--MOhC0";
export var roadFull = "styles-module--roadFull--SFEzi";
export var bld1 = "styles-module--bld1--nPogh";
export var bld2 = "styles-module--bld2--5gVrT";
export var bld3 = "styles-module--bld3--sRKev";
export var bld4 = "styles-module--bld4--Fy57v";
export var bld5 = "styles-module--bld5--ngwBQ";
export var orgBlock1 = "styles-module--orgBlock1--fs6bs";
export var orgBlock2 = "styles-module--orgBlock2--b5uxB";
export var orgBlock3 = "styles-module--orgBlock3--2xcxj";
export var orgBlock4 = "styles-module--orgBlock4--qQQqG";
export var orgBlock5 = "styles-module--orgBlock5--0fh2e";
export var road1 = "styles-module--road1--nog8u";
export var road2 = "styles-module--road2--UjBt-";
export var road3 = "styles-module--road3--Qeroi";
export var tree = "styles-module--tree--l5r-c";
export var organizations__mapFull = "styles-module--organizations__mapFull--qCQ7U";
export var orgAll = "styles-module--orgAll--rgGj+";
export var orgInfo = "styles-module--orgInfo--H5+Fk";