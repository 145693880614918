// extracted by mini-css-extract-plugin
export var payment = "styles-module--payment--iXV6B";
export var payment__accept = "styles-module--payment__accept--d+Iz4";
export var payment__icons = "styles-module--payment__icons--2bJes";
export var payment__wrapper = "styles-module--payment__wrapper--wxRIA";
export var error = "styles-module--error--WLk8H";
export var payment__label = "styles-module--payment__label--FHLnE";
export var payment__input = "styles-module--payment__input--IT7ST";
export var payment__selectWrapper = "styles-module--payment__selectWrapper--5TtBY";
export var payment__expirationContainer = "styles-module--payment__expirationContainer--+U3w5";
export var payment__cvcIcon = "styles-module--payment__cvcIcon--AgEYQ";
export var show = "styles-module--show--JOh6b";