import React from 'react'
import BaseContainer from 'src/components/BaseContainer'
import FAQ from '../components/FAQ/FAQ'
import Meta from '../components/Meta'
import ogGYW from '../images/optimized/png/page-preview.png'

const metaData = {
    title: 'GYW FAQs',
    description: 'GoodYourWay frequently asked questions',
    url: 'https://www.goodyourway.com',
    img: `${ogGYW}`,
    keywords:
        'good your way, social, volunteer, volunteering, fundraising, personal journal, time tracking, impact, charity, nonprofit, gyw, goodyourway, giving back, community',
}

const FaqPage = () => {
    return (
        <>
            <Meta content={metaData} />
            <BaseContainer>
                <FAQ FAQpage={true} />
            </BaseContainer>
        </>
    )
}

export default FaqPage
