export const individuals = [
    {
        title: 'Can I create a fundraising event without volunteering?',
        content: 'No, GYW is aimed to fundraise through volunteering. The point is for the donations to match the time and skills you have contributed. Therefore, in order to create a fundraiser, you must have some volunteering time recorded.',
    },{
        title: 'What counts as volunteering?',
        content: 'Any way you choose to volunteer your time and skills. It can be organized by a nonprofit but it does not have to be. Any good deed can count. For example, you can clean up a neighborhood, tutor a kid, help a neighbor, pretty much do good Your way. If it is an organized event, with a nonprofit, you can specify this organization when recording the volunteering. Otherwise, simply select "self organized" instead of specifying an organization, when recording your volunteering event with the app.',
    },{
        title: 'Do I have to fundraise for the same organization I volunteer for?',
        content: 'No, GYW is designed to allow you to volunteer at one organization and fundraise for the same or different organization, if that is what you want to do. You can also split your fundraising designation across multiple charities. This way you can volunteer locally and fundraise globally.',
    },{
        title: 'Do I need to get my volunteering time approved by the organization where I volunteered?',
        content: 'You do not need to get your time approved. Any volunteering information you record on the GYW app is based on the honor system. In the future, we might add the option for you to get the time explicitly approved by the organization.',
    },{
        title: 'If I use the GYW application, do I have to share and keep my activity public?',
        content: 'No you don\'t. Feel free to use the application as your private journal and share nothing or as little as you\'d like.',
    },{
        title: 'Is GYW only useful for those who volunteer frequently?',
        content: 'No, not at all. The whole point is to welcome and amplify any good deed. Every good deed counts, however infrequent it is.',
    },{
        title: 'Are there fees associated with donation processing?',
        content: 'Yes, there are fees associated with donation processing and disbursement. Please refer to our Terms of Use for the detailed breakdown.',
    },{
        title: 'Does it cost anything to use the GYW application?',
        content: 'The application and all of it’s functionality is free. If you choose to create fundraising events then there are fees associated with the processing and distribution of the donations. A detailed break down of the fees is listed on every fundraising page, as well as always available in our Terms of Use.',
    },{
        title: 'When designating charitable organizations to receive funds, what organizations are eligible?   ',
        content: 'Organizations that are registered with the USA Internal Revenue Service (IRS) as 501(c)(3) charitable organizations, in good standing, can be designated to receive funds. We currently support approximately 2 million organizations in the USA.',
    },{
        title: 'How many organizations can I designate to receive funds?',
        content: 'You can designate up to 5 organizations. You can edit your current designations at any time, in your profile. Your updated selection will be immediately reflected on all of your current fundraising pages.',
    },{
        title: 'Can I use the app to set a volunteering goal, and keep track of my progress?',
        content: 'Yes, you can. You can set a volunteering goal and multiple fundraising goals, and track your progress.',
    },{
        title: 'How can I share my volunteering activity with others?',
        content: 'You can share your volunteering events, goals and fundraising activity directly from the app. You can also generate a more formal report directly from the app and include any information you would like to share with others.',
    },{
        title: 'Can I use the app to invite others to volunteer with me?',
        content: 'Yes, after you have created a volunteering event, you can share it with others.',
    },{
        title: 'Can I record past volunteering time?',
        content: 'Yes, you can always record volunteered time retroactively, by scheduling a volunteering event in the past. Any past volunteering that is added to the app will still count towards your fundraising events.',
    },{
        title: 'What is the purpose of volunteering goals?',
        content: 'Like a personal fitness or meditation goal, it is just for you to keep yourself in check. You can also share it with others, if you choose. They are not necessary for fundraising.',
    },{
        title: 'Can I add my volunteering events from the GYW app to my phone calendar?',
        content: 'Yes, you can. Use the Add to Calendar feature.',
    },{
        title: 'Can I export a report of my volunteering activity?',
        content: 'Yes, you can. Under the Stats tab, use the filter to select any parameters that are of interest to you and then click on the Generate Report button. A report will be emailed to you.',
    },{
        title: 'Where can I view my volunteering history?',
        content: 'You can find it either in the Event tab: Past, or in the Stats tab: Volunteering Log.',
    },{
        title: 'Do volunteering events start automatically at the scheduled start time, or do I have to manually start each event?',
        content: 'You will get a reminder but you must manually start each event. This is done so that you can record your volunteering only if you actually participate.',
    },{
        title: 'In what increments does the volunteered time get recorded?',
        content: 'The time gets recorded in 10 minute increments and always gets rounded up. So if you volunteer for 8 minutes, the time will get rounded up to 10 minutes.',
    },{
        title: 'Can I have more than one fundraising goal at a time?',
        content: 'Yes, you can have as many fundraising goal as you would like, as long as you have enough volunteered time to allocate to all of them. One reason to have multiple volunteering goals is if you want to share different fundraising webpages with different groups people.',
    },{
        title: 'Can I edit or delete my fundraising goal details after I\'ve created it?',
        content: 'You can only delete a fundraising goal while it\'s in the draft state. After it is published (your fundraising webpage has been created), the goal can no longer be deleted. However, certain details can still be updated at any time (like the end date, the donation amount or the personal message from you).',
    },{
        title: 'Can I change my designated charitable organizations?',
        content: 'Yes, you can update selected organizations at any time, in your profile. Updated selection will automatically apply to all of your active fundraising goals.',
    },{
        title: 'Do I have to allocate all of my available volunteered time to one fundraising goal?',
        content: 'No, you can allocate as much or as little available time as you would like. As well as to split it among multiple fundraising goals',
    },{
        title: 'Do I have to designate the same organization I volunteer for to be the fundraising recipient?',
        content: 'No! One important feature is that you can designate charitable organizations that are available through the GYW app. This way you can volunteer in one place and use it to raise funds for different charitable organizations.',
    },{
        title: 'Will my volunteering activity be visible to my fundraiser supporters?',
        content: 'Yes, your volunteering event information (name of organization, type of cause, time volunteered) will be reflected on your personalized fundraising webpage.',
    },{
        title: 'How can I invite individuals and businesses to match my volunteering efforts?',
        content: 'You can create a fundraising goal in the app and then click share. This action will generate a webpage with a unique URL link, which you can send to your supporters via email, post on any social media platform, etc. You will be notified every time someone donates using your unique fundraising webpage.',
    },{
        title: 'Can people forward my personal fundraiser webpage link to others?',
        content: 'Yes, once the personal fundraising webpage has been created and shared, the link can be forwarded by others, to attract even further support to your causes.',
    },{
        title: 'Can I create a fundraising goal without any recorded volunteering time?',
        content: 'No, you have to have at least 10 minutes of volunteering time recorded to be able to create a fundraising goal.',
    },{
        title: 'What is the purpose of having multiple fundraising goals?',
        content: 'You might want to separate your fundraising goals based on your targeted audience. For example, you might want to create a birthday goal that you will share with your friends and family. At the same time, you might create a goal that you would share with your colleagues and employer.',
    },{
        title: 'How can I let my supporters know that I appreciate their help?',
        content: 'Every time someone donates, you will be notified and you can tap “Thank you” in the notification to send them an appreciation email.',
    },{
        title: 'Can I generate a fundraising goal report?',
        content: 'Yes, similar to the volunteering stats report, you can generate one for fundraising. You can filter by any of the available attributes and the report will be emailed to you.',
    },
]

export const сharities = [
    {
        title: 'Charities question?',
        content:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    {
        title: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi?',
        content:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    {
        title: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi?',
        content:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
]
