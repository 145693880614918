import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const ProtectedEmail = props => {
    const { login, domain } = props
    const [href, setHref] = useState('')

    useEffect(() => {
        setHref(`${login}@${domain}.com`)
    }, [])

    return (
        <>
            <a href={`mailto:${href}`}>{href}</a>
            <noscript>
                <span>
                    Email address protected by JavaScript. Activate JavaScript to see the email
                </span>
            </noscript>
        </>
    )
}

ProtectedEmail.propTypes = {
    login: PropTypes.string,
    domain: PropTypes.string,
}

export default ProtectedEmail
