import React from 'react'

import BaseContainer from 'src/components/BaseContainer'
import Meta from 'src/components/Meta'
import Intro from 'src/components/Intro'
import ForIndividuals from 'src/components/ForIndividuals'
import AllowsYouTo from 'src/components/AllowsYouTo'
import Charitable from 'src/components/Charitable'
import About from 'src/components/About'
import DownloadApp from 'src/components/DownloadApp'
import FAQ from 'src/components/FAQ'
import ContactUs from 'src/components/ContactUs'
import ogGYW from '../images/optimized/png/page-preview.png'
import Video from '../components/Video/Video'

const metaData = {
    title: 'Good Your Way - Fundraise through Volunteering',
    description:
        'Many people and organizations are dedicated to better societies. You want to help, in the way that works for you. We support, give recognition, and amplify your impact. Lead by example and make it count.',
    url: 'https://www.goodyourway.com',
    img: `${ogGYW}`,
    keywords:
    'good your way, social, volunteer, volunteering, fundraising, personal journal, time tracking, impact, charity, nonprofit, gyw, goodyourway, giving back, community',

}

const IndexPage = () => (
    <>
        <Meta content={metaData} />
        <BaseContainer>
            <main>
                <Intro />
                <Video />
                <ForIndividuals />
                <AllowsYouTo />
                <Charitable />
                <About />
                <DownloadApp />
                <FAQ />
                <ContactUs />
            </main>
        </BaseContainer>
    </>
)

export default IndexPage
