import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { initDonationSdk } from '../../../utils/initDonationSdk'

import * as styles from './styles.module.scss'

const SupportButton = props => {
    const { setShowForm, data } = props
    const { disable_payment_form, status } = data

    const [scrollToForm, setScrollToForm] = useState(false)

    const handleClick = () => {
        setShowForm(true)
        setScrollToForm(true)
        initDonationSdk()
    }

    const buttonDisable = disable_payment_form || status === 'BLOCKED'

    useEffect(() => {
        if (scrollToForm) {
            const smoothLinks = document.querySelectorAll('[data-anchor="#donationForm"]')
            Array.from(smoothLinks).map(smoothLink => {
                smoothLink.addEventListener('click', function (e) {
                    e.preventDefault()
                    const id = smoothLink.getAttribute('data-anchor')

                    document.querySelector(id).scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    })
                })
            })
        }
    }, [scrollToForm])

    return (
        <button
            data-anchor="#donationForm"
            className={styles.supportButton}
            onMouseDown={handleClick}
            disabled={buttonDisable}
        >
            {!buttonDisable ? 'Support my efforts' : 'This event is over'}
        </button>
    )
}

SupportButton.propTypes = {
    setShowForm: PropTypes.func,
    data: PropTypes.object,
}

export default SupportButton
