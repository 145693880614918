import React from 'react'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import classNames from 'classnames'

import Navigation from '../Navigation'
import SvgSprite from '../SvgSprite'

import * as styles from './styles.module.scss'

const Header = () => {
    const headerWrapClass = classNames(styles.wrapper, 'container')

    return (
        <header className={styles.header}>
            <div className={headerWrapClass}>
                <Link to="/">
                    <SvgSprite name="logo" className={styles.logo} />
                </Link>
                <Navigation />
                <AnchorLink to="/#download-app" className={styles.getApp}>
                    Get
                    <SvgSprite name="getAppGYW" className={styles.app} />
                    app
                </AnchorLink>
            </div>
        </header>
    )
}

export default Header
