import React from 'react'
import PropTypes from 'prop-types'

import { SpriteLoader } from 'src/components/SvgSprite'

import Unsubscribe from '../../components/Unsubscribe'

const UnsubscribePage = props => {
    return (
        <main>
            <SpriteLoader />
            <Unsubscribe emailInfo={props.location.search} />
        </main>
    )
}

UnsubscribePage.propTypes = {
    location: PropTypes.object,
}

export default UnsubscribePage
