// extracted by mini-css-extract-plugin
export var raising = "styles-module--raising--T-d2U";
export var wrapper = "styles-module--wrapper--Uugfw";
export var withGoal = "styles-module--withGoal--CGGZx";
export var date = "styles-module--date--7XpAC";
export var cardWrapper = "styles-module--cardWrapper--RZHgZ";
export var goal = "styles-module--goal--Cn+fM";
export var info = "styles-module--info--lK9lr";
export var funds = "styles-module--funds--DQiYr";
export var hours = "styles-module--hours--cxoz2";
export var title = "styles-module--title--LChZG";
export var icon = "styles-module--icon--OCvWP";
export var minutes = "styles-module--minutes--TwQEl";
export var visualInfo = "styles-module--visualInfo--qZvv1";
export var visualContainer = "styles-module--visualContainer--3YUbS";
export var inner = "styles-module--inner--XDvnn";