const submit = (values, setSubmitting, notifiedForm) => {
    const portalId = '20356689'
    const formId = notifiedForm
        ? '124a9f54-802f-4889-b597-abe6a49a23e1'
        : '06da7b4e-def3-4763-81eb-d319facb256b'
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`

    const {
        firstname,
        lastname,
        email,
        message,
        data,
        subscribed,
        company,
        a_registered_501_c__3__organization,
    } = values
    const inputValues = {
        fields: [
            {
                name: 'firstname',
                value: firstname,
            },
            {
                name: 'lastname',
                value: lastname,
            },
            {
                name: 'email',
                value: email,
            },
            {
                name: 'message',
                value: message,
            },
        ],
        legalConsentOptions: {
            consent: {
                consentToProcess: data,
                text:
                    'I agree to allow Good Your Way, Inc. to store and process my personal data according to its Privacy Policy',
                communications: [
                    {
                        value: subscribed,
                        subscriptionTypeId: 1,
                        text:
                            'I agree to receive periodic communications from Good Your Way, Inc. according to its Privacy Policy',
                    },
                ],
            },
        },
    }

    if (notifiedForm) {
        inputValues.fields.push(
            {
                name: 'company',
                value: company,
            },
            {
                name: 'a_registered_501_c__3__organization',
                value: a_registered_501_c__3__organization,
            }
        )
    }

    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(inputValues),
    }).then(function (response) {
        if (response.status >= 200 && response.status < 300) {
            setSubmitting(true)
            return response
        } else {
            alert('Error! Please try again')
        }
    })
}

export default submit
