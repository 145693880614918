import React from 'react'
import PT from 'prop-types'
import classNames from 'classnames'

import Form from '../Form'
import ModalTemplate from '../ModalTemplate'
import notifiedValidate from './notifiedValidate'

import * as styles from './styles.module.scss'

const ModalGetNotified = props => {
    const { closeModal } = props

    const formValues = {
        firstname: '',
        lastname: '',
        company: '',
        a_registered_501_c__3__organization: false,
        email: '',
        message: '',
        data: false,
        subscribed: false,
    }

    const useIphone = navigator.userAgent.toLowerCase().match(/(ipad|iphone)/)
    const notifiedClass = classNames(styles.notified, useIphone && styles.useIphone)

    return (
        <ModalTemplate closeModal={closeModal}>
            <section className={notifiedClass}>
                <h2 className={styles.title}>Get notified</h2>
                <Form
                    customClass={styles.notifiedForm}
                    notifiedForm={true}
                    initialValues={formValues}
                    validate={notifiedValidate}
                />
            </section>
        </ModalTemplate>
    )
}

ModalGetNotified.propTypes = {
    closeModal: PT.func,
}

export default ModalGetNotified
