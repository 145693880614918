import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import * as styles from './styles.module.scss'

const photo = '../../images/optimized/png/founder-picture.png'

const PersonalNote = () => {
    return (
        <div className={styles.PersonalNote}>
            <div className={styles.photoContainer}>
                <div className={styles.photoWrapper}>
                    <StaticImage
                        src={photo}
                        formats={['AUTO', 'WEBP', 'AVIF']}
                        alt="GYW founders' picture"
                        layout="constrained"
                        placeholder="tracedSVG"
                    />
                </div>
            </div>
            <div className={styles.text}>
                <p>
                    &quot;Personal note: We enjoy volunteering and have been donating to causes
                    close to our hearts for many years. We have always tried to help in a way that
                    worked for us. It is also important for us that our son would grow up with
                    empathy for his surroundings and understand that people do not live in a vacuum,
                    that mutual support and caring are important aspects of our lives.
                </p>
                <br />
                <p>
                    Over the years, we have asked ourselves many times how our small actions can
                    make a bigger impact, and the idea of GYW was conceived. The idea that
                    supporting people&apos;s good will and efforts can be profitable for businesses
                    and made simple for individuals. We invite you to join the movement and do Good
                    Your Way.&quot; <span>- Alex &amp; Marina</span>
                </p>
            </div>
        </div>
    )
}

export default PersonalNote
