import React from 'react'
import PT from 'prop-types'
import { Helmet } from 'react-helmet'

const Meta = ({ content }) => {
    const {
        title,
        description,
        type = 'website',
        url,
        img,
        keywords,
        twitterCard = 'summary_large_image',
    } = content
    return (
        <Helmet
            title={title}
            meta={[
                {
                    name: 'description',
                    content: description,
                },
                {
                    name: 'keywords',
                    content: keywords,
                },
                {
                    property: 'og:title',
                    content: title,
                },
                {
                    property: 'og:site_name',
                    content: title,
                },
                {
                    property: 'og:description',
                    content: description,
                },
                {
                    property: 'og:image',
                    content: img,
                },
                {
                    property: 'og:url',
                    content: url,
                },
                {
                    property: 'og:type',
                    content: type,
                },
                {
                    name: 'twitter:title',
                    content: title,
                },
                {
                    name: 'twitter:description',
                    content: description,
                },
                {
                    name: 'twitter:url',
                    content: url,
                },
                {
                    name: 'twitter:card',
                    content: twitterCard,
                },
                {
                    name: 'twitter:image',
                    content: img,
                },
            ]}
        />
    )
}

Meta.propTypes = {
    content: PT.shape({
        title: PT.string.isRequired,
        description: PT.string.isRequired,
        url: PT.string.isRequired,
        img: PT.string.isRequired,
        keywords: PT.string.isRequired,
        type: PT.string,
        twitterCard: PT.string,
    }),
}

export default Meta

// /*
// *
// * if you're going to use it in Gatsby, add it to the file gatsby-ssr.js next api:
// *
// * export const onRenderBody = ({ setHtmlAttributes }) => {
// *   setHtmlAttributes({
// *     prefix: "og: http://ogp.me/ns#"
// *   })
// * }
// *
// */
