import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as styles from './styles.module.scss'

const List = ({ title, data, color, className }) => {
    const listTitleClassName = classNames(styles.title,{[styles[color]]:color})
    const listElementClassName = classNames(styles.listElement,{[styles[className]]:className})

    return (
        <div className={listElementClassName}>
            <h2 className={listTitleClassName}>{title}</h2>
            <ul className={styles.list}>
                {data.map((el,idx) => {
                    const { text, link = undefined }  = el || {}
                    return (
                    <li className={styles.listItem} key={idx}>
                        <p className={styles.text}>{typeof el === "object" ? text : el}{' '}
                        {typeof el === "object" && link && (
                            <>
                            <a href={link?.href} className={styles.link} target="_blank" rel="noopener nonreferrer">
                                {link?.name}
                            </a>
                            <span>.</span>
                            </>
                        )}
                        </p>
                    </li>
                )})}
            </ul>
        </div>
    )
}

List.propTypes = {
    title: PropTypes.string,
    color: PropTypes.string,
    data: PropTypes.any,
    customClass: PropTypes.string
}

export default List
