import React from 'react'
import PT from 'prop-types'
import classNames from 'classnames'

import * as styles from './styles.module.scss'

const Input = props => {
    const {
        name,
        type,
        required,
        touched,
        errors,
        values,
        className,
        onChange,
        disabled = false,
        ...other
    } = props

    const inputClass = classNames(styles.input, className)

    const fieldClassName = (name, className) => {
        if (required) {
            return classNames(className, {
                [styles.error]: errors[name] && touched[name],
            })
        } else {
            return className
        }
    }
    return (
        <>
            {type === 'textarea' ? (
                <textarea
                    name={name}
                    className={fieldClassName(name, styles.textarea)}
                    onChange={onChange}
                    {...other}
                />
            ) : type === 'checkbox' ? (
                <input
                    type={type}
                    name={name}
                    checked={values[name]}
                    disabled={disabled}
                    onChange={onChange}
                    className={fieldClassName(name, className)}
                    {...other}
                />
            ) : (
                <input
                    name={name}
                    type={type}
                    onChange={onChange}
                    className={fieldClassName(name, inputClass)}
                    {...other}
                />
            )}

            {required && type !== 'checkbox' && !values[name] && (
                <span className={styles.required} data-name="error" />
            )}
            {required && touched[name] && errors[name] && (
                <p className={styles.error} data-name="error">
                    {errors[name]}
                </p>
            )}
        </>
    )
}

Input.propTypes = {
    name: PT.string.isRequired,
    type: PT.string,
    touched: PT.object,
    errors: PT.object,
    required: PT.bool,
    values: PT.object,
    className: PT.string,
    onChange: PT.func,
    disabled: PT.bool,
    other: PT.any,
}

export default Input
