import React from 'react'
import BaseContainer from 'src/components/BaseContainer'
import Meta from 'src/components/Meta'
import ogGYW from '../images/optimized/png/page-preview.png'
import HowItWorks from '../components/how-it-works/HowItWorks'
import DonationProcessing from '../components/how-it-works/DonationProcessing'
import Conditions from '../components/how-it-works/Conditions/Conditions'

const metaData = {
    title: 'How GYW works',
    description:
        'How GoodYourWay platform works',
    url: 'https://www.goodyourway.com',
    img: `${ogGYW}`,
    keywords: 'good your way, social, volunteer, volunteering, fundraising, personal journal, time tracking, impact, charity, nonprofit, gyw, goodyourway, giving back, community',
}

const HowItWorksPage = () => (
    <>
        <Meta content={metaData} />
        <BaseContainer>
            <main>
                <HowItWorks/>
                <Conditions/>
                <DonationProcessing/>
            </main>
        </BaseContainer>
    </>
)

export default HowItWorksPage;