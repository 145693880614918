import React from 'react'

import Fb from '../../images/optimized/svg/socials/facebook.inline.svg'
import Instagram from '../../images/optimized/svg/socials/instagram.inline.svg'
import Linkedin from '../../images/optimized/svg/socials/linkedin.inline.svg'
import Youtube from '../../images/optimized/svg/socials/youtube.inline.svg'
import * as styles from './styles.module.scss'

const socialLinksData = [
    {
        link: 'https://www.facebook.com/GoodYourWay',
        name: 'Facebook',
        component:<Fb/>
    },
    {
        link: 'https://www.instagram.com/goodyourway_gyw',
        name: 'Instagram',
        component:<Instagram/>
    },
    {
        link: 'https://www.linkedin.com/company/goodyourway',
        name: 'LinkedIn',
        component:<Linkedin/>
    },
    {
        link: ' https://www.youtube.com/@goodyourwaygyw7129',
        name: 'YouTube',
        component:<Youtube/>
    },
]

const SocialLinks = () => {
    return (
        <div className={styles.socialLinks}>
            <p className={styles.text}>Follow us</p>
            <div className={styles.iconsWrapper}>
                {socialLinksData.map(({link,component,name}) => (
                    <a
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {component}
                    </a>

                ))}
            </div>
        </div>
    )
}

export default SocialLinks
