import React from 'react';

import * as styles from './styles.module.scss'
import Advantages from './Advantages'

const ForIndividuals = () => (
    <section className={styles.forIndividuals}>
        <span id="individuals" className="anchor" />
        <div className="container wrapper">
            <h2 className={styles.title}>For Individuals</h2>
            <Advantages/>
        </div>
    </section>
)

export default ForIndividuals
