import React from 'react'
import PT from 'prop-types'
import className from 'classnames'

import ModalTemplate from '../ModalTemplate'

import * as styles from './styles.module.scss'

const classNameBtnClose = className('btnSecondary', styles.btnClose)

const ModalSuccessfulSubmitted = ({ formReset, subscribed }) => {
    const closeModal = () => {
        formReset()
    }

    return (
        <ModalTemplate closeModal={closeModal}>
            <div className={styles.wrapper}>
                {subscribed ? (
                    <>
                        <h2 className={styles.title}>
                            Thank you for <br /> your interest
                        </h2>
                        <p className={styles.message}>
                            We will provide you with meaningful updates.
                        </p>
                    </>
                ) : (
                    <>
                        <h2 className={styles.title}>
                            Thank you for <br /> contacting us
                        </h2>
                    </>
                )}

                <button className={classNameBtnClose} onClick={closeModal}>
                    Close
                </button>
            </div>
        </ModalTemplate>
    )
}

ModalSuccessfulSubmitted.propTypes = {
    formReset: PT.func,
    subscribed: PT.bool,
}

export default ModalSuccessfulSubmitted
