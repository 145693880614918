import React from 'react'
import * as styles from './styles.module.scss'
import { Advantages } from '../../ForIndividuals'

const HowItWorks = () => {
    return (
        <section className={styles.hero}>
            <div className="container">
                <h1 className={styles.title}>How GoodYourWay works</h1>
                <h2 className={styles.subtitle}>
                    <span>Fundraise</span> <span>through</span> <span>Volunteering</span>
                </h2>
                <p className={styles.text}>
                    The GYW application is your personal volunteering journal. It makes it easy to
                    organize and keep track of your volunteering activity.
                </p>
                <p className={styles.text}>
                    {' '}
                    The track record of your good deeds allows you, with a click of a button, to
                    fundraise for charitable organizations of your choice.
                </p>
                <Advantages isFromHowItWorksPage />
            </div>
        </section>
    )
}

export default HowItWorks
