import React from 'react'
import classNames from 'classnames'
import PT from 'prop-types'
import { Link } from 'gatsby'

import Modal from '../Modal'
import SvgSprite from '../SvgSprite'

import * as styles from './styles.module.scss'

const ModalTemplate = ({ closeModal, children, className }) => {
    const customModalClass = classNames(styles.ModalTemplate, className)
    return (
        <Modal onClose={closeModal}>
            <div className={customModalClass}>
                <header className={styles.modalHeader}>
                    <Link to="/" className={styles.logo}>
                        <SvgSprite name="logo" className={styles.logo} />
                    </Link>
                    <div className={styles.close} onClick={closeModal}>
                        <div className={styles.strip}></div>
                        <div className={styles.strip}></div>
                    </div>
                </header>
                <div className={styles.content}>{children}</div>
            </div>
        </Modal>
    )
}

ModalTemplate.propTypes = {
    closeModal: PT.func.isRequired,
    children: PT.element,
    className: PT.string,
}

export default ModalTemplate
