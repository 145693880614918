import { getAccessToken, verifyCard, tokenizeCard, sendPaymentInfo } from './api.js'

const donationSubmit = async (values, data, setSubmitted, cardFeeRate, charityId, totalFees) => {
    setSubmitted('progress')
    try {
        const accessToken = await getAccessToken()
        const verify = await verifyCard(values, accessToken.data.access_token)
        if (verify?.data?.Successful === true) {
            const accesToken2 = await getAccessToken()
            const tokinize = await tokenizeCard(accesToken2.data?.access_token, values, charityId)
            if (tokinize?.tokenGuid) {
                await sendPaymentInfo(
                    tokinize.tokenGuid,
                    values,
                    data.goal_id,
                    cardFeeRate,
                    totalFees
                )
                setSubmitted(true)
            } else {
                throw setSubmitted('error')
            }
        }
    } catch (e) {
        if (e.response?.data[0]?.ErrorCode === 1088) {
            setSubmitted('decline')
        } else {
            setSubmitted('error')
        }
    }
}

export default donationSubmit
