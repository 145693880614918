import { calcFees, getAccessToken, tokenizeCard } from './api.js'

export const getFeesInfo = async (values, charityId, data) => {
    let nonprofitArray = []
    data.nonprofit_designations.map(({ charity_id, percentage }) => {
        return nonprofitArray.push({
            charity_id: charity_id,
            percentage: percentage,
        })
    })

    const accesToken = await getAccessToken()
    const tokinize = await tokenizeCard(accesToken.data?.access_token, values, charityId)
    if (tokinize?.tokenGuid) {
        return await calcFees(tokinize.tokenGuid, nonprofitArray, values.amount)
    }
}
