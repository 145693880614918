import React, { useState } from 'react'
import PT from 'prop-types'
import { useFormik } from 'formik'
import classNames from 'classnames'

import submit from '../formsubmit/formsubmit.js'
import Input from '../elements/Input'
import ModalSuccessfulSubmitted from '../ModalSuccessfulSubmitted/'

import * as styles from './styles.module.scss'
import Checkbox from '../elements/Checkbox/Checkbox.js'

const Form = props => {
    const { notifiedForm, initialValues, validate, customClass } = props

    const [isSubmitting, setSubmitting] = useState(false)

    const formik = useFormik({
        initialValues: initialValues,
        validate,
        onSubmit: values => {
            submit(values, setSubmitting, notifiedForm)
        },
    })

    const { handleSubmit, handleChange, handleBlur, handleReset, values, errors, touched } = formik

    const disableSubmit = () => {
        if (
            !values.firstname ||
            !values.email ||
            !values.data ||
            errors.firstname ||
            errors.email
        ) {
            return true
        } else if (!notifiedForm && !values.message) {
            return true
        } else if (notifiedForm) {
            if (!values.company || !values.subscribed) {
                return true
            }
        } else {
            return false
        }
    }

    const resetForm = () => {
        handleReset()
        setSubmitting(false)
    }

    const formClass = classNames(styles.form, customClass)

    return (
        <>
            <form className={formClass} onSubmit={handleSubmit}>
                <div className={styles.inputsContainer}>
                    <div>
                        <Input
                            type="text"
                            id="firstname"
                            name="firstname"
                            placeholder="First Name"
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.firstname}
                            touched={touched}
                            errors={errors}
                            values={values}
                        />
                        <Input
                            type="text"
                            id="lastname"
                            name="lastname"
                            placeholder="Last Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.lastname}
                        />
                    </div>
                    <div data-name="emailContainer">
                        {notifiedForm && (
                            <>
                                <Input
                                    type="text"
                                    id="company"
                                    name="company"
                                    placeholder="Name of your organization"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.company}
                                    touched={touched}
                                    errors={errors}
                                    values={values}
                                    required={true}
                                />
                                <Checkbox
                                    name="a_registered_501_c__3__organization"
                                    labelText="A registered 501(c)(3) organization"
                                    onChange={handleChange}
                                    onClick={handleBlur}
                                    values={values}
                                />
                            </>
                        )}
                        <div data-name="emailWrapper">
                            <Input
                                type="email"
                                id="email"
                                name="email"
                                placeholder="E-mail"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                touched={touched}
                                errors={errors}
                                values={values}
                                required={true}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.textareaWrapper}>
                    <Input
                        type="textarea"
                        name="message"
                        placeholder="Tell us what's on your mind."
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.message}
                        touched={touched}
                        errors={errors}
                        values={values}
                        required={!notifiedForm}
                    />
                </div>
                <div className={styles.agreement}>
                    <span className={styles.agreement__title}>
                        At Good Your Way we respect your time and privacy.
                    </span>
                    <ul className={styles.agreement__list}>
                        <li>
                            <p>
                                In order to provide you the content requested, we need to store and
                                process your personal data.
                            </p>
                            <div className={styles.agreement__checkboxContainer}>
                                <Checkbox
                                    name="data"
                                    onChange={handleChange}
                                    onClick={handleBlur}
                                    link={true}
                                    labelText="I agree to allow Good Your Way, Inc. to store and
                                    process my personal data according to its"
                                    required={true}
                                    touched={touched}
                                    errors={errors}
                                    values={values}
                                />
                            </div>
                        </li>
                        <li>
                            <p>
                                From time to time, we would like to contact you about our products
                                and services.
                            </p>
                            <div className={styles.agreement__checkboxContainer}>
                                <Checkbox
                                    name="subscribed"
                                    onChange={handleChange}
                                    onClick={handleBlur}
                                    labelText=" I agree to receive periodic communications from Good
                                    Your Way, Inc. according to its"
                                    link={true}
                                    touched={touched}
                                    errors={errors}
                                    required={notifiedForm}
                                    values={values}
                                />
                            </div>
                        </li>
                    </ul>
                </div>
                <button type="submit" className={styles.btnSend} disabled={disableSubmit()}>
                    Send
                </button>
            </form>
            {isSubmitting && (
                <ModalSuccessfulSubmitted formReset={resetForm} subscribed={values.subscribed} />
            )}
        </>
    )
}

Form.propTypes = {
    notifiedForm: PT.bool,
    initialValues: PT.object.isRequired,
    validate: PT.func.isRequired,
    customClass: PT.string,
}

export default Form
