// extracted by mini-css-extract-plugin
export var activity = "styles-module--activity--LJsi4";
export var activity__title = "styles-module--activity__title--Lcd3D";
export var activity__item = "styles-module--activity__item--PjNgQ";
export var textContainer = "styles-module--textContainer--cXrTR";
export var name = "styles-module--name--vbu-f";
export var goalList = "styles-module--goalList--X-gBy";
export var goal = "styles-module--goal--CQ+Kr";
export var activity__icon = "styles-module--activity__icon--jCSEu";
export var activity__goalSmall = "styles-module--activity__goalSmall--Vt8Sy";
export var activity__goalLarge = "styles-module--activity__goalLarge--6MCGb";
export var activity__hours = "styles-module--activity__hours--4U072";