import React from 'react'

import Meta from 'src/components/Meta'
import BaseContainer from 'src/components/BaseContainer'
import TermsOfUse from '../components/TermsOfUse'

import ogGYW from '../images/optimized/png/page-preview.png'

const metaData = {
    title: 'Good Your Way - Terms of Use',
    description: 'Good Your Way, Inc Terms of Use',
    url: 'https://www.goodyourway.com/terms-of-use',
    img: `${ogGYW}`,
    keywords: 'Terms of Use',
}

const PrivacyPolicy = () => {
    return (
        <>
            <Meta content={metaData} />
            <BaseContainer>
                <TermsOfUse />
            </BaseContainer>
        </>
    )
}

export default PrivacyPolicy
