import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import DownloadBtns from '../DownloadBtns'

import * as styles from './styles.module.scss'

const intro = '../../images/intro.png'

const Intro = () => (
    <section className={styles.intro}>
        <div className="container">
            <div className={styles.img}>
                <StaticImage
                    src={intro}
                    formats={['AUTO', 'WEBP', 'AVIF']}
                    alt="goodYourWay - volunteers"
                    layout="constrained"
                    placeholder="tracedSVG"
                    loading="eager"
                />
            </div>
            <h1 className={styles.title}>
                Fundraise <span className={styles.your}>through</span> Volunteering
            </h1>
            <p className={styles.text}>
                Many people and organizations are dedicated to making societies better. <br /> You
                want to help, in the way that works for you. We support, give recognition, and
                amplify your impact. Lead by example and make it count.
            </p>
            <div className={styles.downloadApp}>
                <DownloadBtns />
            </div>
        </div>
    </section>
)

export default Intro
