import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce'

import Checkbox from '../../../elements/Checkbox'
import Input from '../../../elements/Input'
import SvgSprite from '../../../SvgSprite'
import ModalTemplate from '../../../ModalTemplate'
import { formatAmount } from '../donationHelpers'
import { getFeesInfo } from '../donationHelpers/getFeesInfo.js'

import * as styles from './styles.module.scss'

const FeesLabel = props => {
    const { setShow, totalFees, getingFees } = props

    return (
        <div className={styles.fees}>
            <div className={styles.fees__mainInfo}>
                <span className={styles.fees__label}>Cover donation fees</span>
                {getingFees === 'progress' ? (
                    <span className={styles.fees__calc}>Calculating fees...</span>
                ) : (
                    <span className={styles.fees__quantity}> ${totalFees}</span>
                )}
            </div>
            <span className={styles.fees__details}>
                Card details and donation amount required to compute fees{' '}
            </span>
            <span className={styles.fees__disclosure}>
                Fees disclosure
                <span
                    onMouseEnter={() => {
                        setShow(true)
                    }}
                    onMouseLeave={() => {
                        setShow(false)
                    }}
                >
                    <SvgSprite name="question" />
                </span>
            </span>
        </div>
    )
}

const AmountInfo = props => {
    const {
        data,
        touched,
        errors,
        values,
        handleChange,
        handleClick,
        handleSubmit,
        handleBlur,
        setShow,
        totalFees,
        setTotalFees,
        setCardFeeRate,
        charityId,
    } = props
    const { amount, cardNum } = values
    const { first_name, nonprofit_designations } = data

    const [getingFees, setGetingFees] = useState(false)

    const calcFees = useDebouncedCallback(async () => {
        if (values.coverFees === true) {
            setGetingFees('progress')
            if (
                cardNum.split(' ').join('').length === 15 ||
                cardNum.split(' ').join('').length === 16
            ) {
                try {
                    const feesInfo = await getFeesInfo(values, charityId, data)
                    if (feesInfo.data) {
                        setGetingFees(true)
                        setCardFeeRate(feesInfo.data.card_fee_rate)
                        setTotalFees(feesInfo.data.total_fees)
                    }
                } catch (e) {
                    setGetingFees('error')
                }
            }
        } else {
            setCardFeeRate(0)
            setTotalFees(0)
        }
    }, 1000)

    useEffect(() => {
        calcFees()
    }, [values.coverFees, values.cardNum, values.amount])

    const submitDisabled = () => {
        const emptyValue = Object.values(values).filter(i => i?.length === 0)
        const isDisable =
            emptyValue.length === 0 && Object.keys(errors).length === 0 && values.privacy === true
        return !isDisable || getingFees === 'progress' || Number(amount) + totalFees > 9999.99
    }

    return (
        <div className={styles.amount}>
            <div className={styles.amount__inform}>
                <p>
                    According to {first_name}&apos;s choice, your donation will be distributed to
                    the following charitable organizations:
                </p>
                {nonprofit_designations?.map(({ organization_name, percentage }) => (
                    <span key={organization_name}>
                        {organization_name} - {percentage}%
                    </span>
                ))}
                <p>
                    <span>
                        Your donation benefitting the above organizations is being distributed by
                        Panorama Global Fund (EIN: 26-3265577), a 501(c)3 non-profit organization.
                    </span>
                    On behalf of Panorama Global Fund we hereby attest that no goods or services of
                    any kind are provided to you by us in consideration for this contribution.
                </p>
            </div>
            <label htmlFor={'amount'} className={styles.amount__label}>
                Choose a one-time donation amount
            </label>
            <div className={styles.amount__inputWrapper}>
                <div>
                    <Input
                        name="amount"
                        value={formatAmount(values.amount)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="$"
                        className={styles.amount__input}
                        type="number"
                        touched={touched}
                        errors={errors}
                        values={values}
                        required={true}
                    />
                </div>
                <div className={styles.amount__checkboxWrapper}>
                    <Checkbox
                        name="coverFees"
                        link={false}
                        terms={false}
                        labelText={
                            <FeesLabel
                                setShow={setShow}
                                totalFees={totalFees}
                                getingFees={getingFees}
                                setTotalFees={setTotalFees}
                            />
                        }
                        required={false}
                        touched={touched}
                        errors={errors}
                        values={values}
                        onChange={handleChange}
                        onClick={handleClick}
                        disabled={Boolean(Object.keys(errors).length)}
                    />
                </div>
            </div>
            <div className={styles.amount__checkboxWrapper}>
                <Checkbox
                    name="privacy"
                    link={true}
                    terms={true}
                    labelText="I accept Good Your Way, Inc "
                    required={true}
                    touched={touched}
                    errors={errors}
                    values={values}
                    onChange={handleChange}
                    onClick={handleClick}
                />
            </div>
            <button
                className={styles.amount__button}
                type="submit"
                onClick={handleSubmit}
                disabled={submitDisabled()}
            >
                {getingFees === 'progress'
                    ? 'Calculating fees...'
                    : `Donate $${formatAmount(Number(amount) + totalFees)}`}
            </button>
            {Number(amount) + totalFees > 9999.99 && (
                <span className={styles.error}>Amount must not exceed $9999.99</span>
            )}
            {getingFees === 'error' && (
                <ModalTemplate
                    className={styles.feesModal}
                    closeModal={() => {
                        values.coverFees = false
                        setGetingFees(false)
                    }}
                >
                    <div className={styles.modalMessage}>
                        <span>Error!</span>
                        <p>Please, check your card details</p>
                    </div>
                </ModalTemplate>
            )}
        </div>
    )
}

FeesLabel.propTypes = {
    setShow: PropTypes.func,
    totalFees: PropTypes.number,
    getingFees: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

AmountInfo.propTypes = {
    values: PropTypes.object,
    touched: PropTypes.object,
    errors: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleClick: PropTypes.func,
    data: PropTypes.object,
    setShow: PropTypes.func,
    totalFees: PropTypes.number,
    setTotalFees: PropTypes.func,
    setCardFeeRate: PropTypes.func,
    charityId: PropTypes.string,
}

export default AmountInfo
