import React from 'react'

import Meta from 'src/components/Meta'
import BaseContainer from 'src/components/BaseContainer'
import PrivacyContent from '../components/PrivacyContent'

import ogGYW from '../images/optimized/png/page-preview.png'

const metaData = {
    title: 'Good Your Way - Privacy Policy',
    description: 'Good Your Way, Inc Privacy Policy',
    url: 'https://www.goodyourway.com/privacy-policy',
    img: `${ogGYW}`,
    keywords: 'privacy policy',
}

const PrivacyPolicy = () => {
    return (
        <>
            <Meta content={metaData} />
            <BaseContainer>
                <PrivacyContent />
            </BaseContainer>
        </>
    )
}

export default PrivacyPolicy
