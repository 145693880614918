import React from 'react'
import List from '../../elements/List/List';
import {dataForLists as conditionsData} from './constants';
import * as styles from './styles.module.scss'


const Conditions = () => {
    return (
        <section className={styles.conditions}>
            <div className="container">
                <List title="Volunteers:" data={conditionsData.volunteers} color="blue" className="withMargin"/>
                <List title="Donors:" data={conditionsData.donors} color="green" className="withMargin"/>
                <List title="Charities:" data={conditionsData.charities} />
            </div>
        </section>
    )
}

export default Conditions
