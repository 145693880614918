import React from 'react'
import PT from 'prop-types'
import SvgSpriteIcon from '../../SvgSprite/SvgSpriteIcon'
import Input from '../Input'

import * as styles from './styles.module.scss'

const Checkbox = props => {
    const {
        name,
        labelText,
        onChange,
        onClick,
        touched,
        errors,
        link,
        required,
        values,
        terms,
        disabled = false,
        ...other
    } = props

    return (
        <>
            <Input
                type="checkbox"
                name={name}
                onChange={onChange}
                onClick={onClick}
                className={styles.checkbox}
                touched={touched}
                errors={errors}
                required={required}
                values={values}
                disabled={disabled}
                {...other}
            />
            <label htmlFor={name} className={styles.label}>
                {labelText}{' '}
                {link && (
                    <a href="/privacy-policy" target="_blank" rel="noreferrer">
                        Privacy Policy
                    </a>
                )}
                {terms && (
                    <>
                        {' '}
                        and{' '}
                        <a href="/terms-of-use" target="_blank" rel="noreferrer">
                            Terms of Use
                        </a>
                    </>
                )}
                {required && <span>*</span>}
            </label>
            <SvgSpriteIcon name="check" className={styles.iconCheck} />
        </>
    )
}

Checkbox.propTypes = {
    name: PT.string.isRequired,
    labelText: PT.any.isRequired,
    onChange: PT.func.isRequired,
    onClick: PT.func,
    touched: PT.object,
    errors: PT.object,
    link: PT.bool,
    required: PT.bool,
    values: PT.object,
    terms: PT.bool,
    disabled: PT.bool,
    other: PT.any,
}

export default Checkbox
