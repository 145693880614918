import React from 'react'
import PT from 'prop-types'
import classNames from 'classnames'

import './styles.scss'

const Card = ({ className, title, text, children }) => {
    const wrapperClass = classNames(className, 'card')

    return (
        <div className={wrapperClass}>
            {children && <div className="card__img">{children}</div>}
            <div className="card__info">
                <h3 className="card__title">{title}</h3>
                <p className="card__text">{text}</p>
            </div>
        </div>
    )
}

Card.propTypes = {
    className: PT.string,
    title: PT.string,
    text: PT.string,
    children: PT.element,
}
export default Card
