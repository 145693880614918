import React from 'react'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'
import ReactHtmlParser from 'react-html-parser'

import SupportButton from '../SupportButton/SupportButton'
import * as styles from './styles.module.scss'

const User = props => {
    const { data, setShowForm } = props
    const { first_name, personal_message, user_avatar_url } = data

    const defaultUserIcon = '../../../images/optimized/png/user.png'

    const formatMessage = text => {
        if (text.trim().length) {
            return ReactHtmlParser(`“${text.split('\n').join('<br/>')}”`)
        } else {
            return
        }
    }

    return (
        <section className={styles.user}>
            <div className="fundraisingContainer">
                <div className={styles.user__photoWrapper}>
                    <div className={styles.user__photo}>
                        {user_avatar_url === null ? (
                            <StaticImage
                                src={defaultUserIcon}
                                formats={['AUTO', 'WEBP', 'AVIF']}
                                alt="User default avatar"
                                layout="constrained"
                                placeholder="tracedSVG"
                            />
                        ) : (
                            <img src={user_avatar_url} alt="User photo" />
                        )}
                    </div>
                </div>
                <div className={styles.user__support}>
                    <h1 className={styles.user__title}>
                        Support <span> {first_name}’s volunteering time and effort </span>
                        <br />
                        by donating to organizations
                        <br />
                        of their choice
                    </h1>
                    <SupportButton setShowForm={setShowForm} data={data} />
                    <div className={styles.user__supportBackgroundImg} />
                </div>
                <div className={styles.user__info}>
                    <p className={styles.user__infoMessage}>{formatMessage(personal_message)}</p>
                    <div className={styles.user__photoWrapper}>
                        <div className={styles.user__photo}>
                            {user_avatar_url === null ? (
                                <StaticImage
                                    src={defaultUserIcon}
                                    formats={['AUTO', 'WEBP', 'AVIF']}
                                    alt="User default avatar"
                                    layout="constrained"
                                    placeholder="tracedSVG"
                                />
                            ) : (
                                <img src={user_avatar_url} alt="User photo" />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

User.propTypes = {
    data: PropTypes.object,
    setShowForm: PropTypes.func,
}

export default User
