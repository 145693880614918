import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import classNames from 'classnames'
import PropTypes, { bool } from 'prop-types'
import * as styles from './styles.module.scss'

const Advantages = ({ isFromHowItWorksPage}) => {
    const advantagesClass = classNames(styles.advantages, {
        [styles.howItWorks]: isFromHowItWorksPage,
    });
    const imgWrapperClass=classNames(styles.imgWrapper, {
        [styles.howItWorks]: isFromHowItWorksPage,
    });
    const imgTextClass=classNames(styles.imgText, {
        [styles.howItWorks]: isFromHowItWorksPage,
    });

    const advantagesBlockClass = classNames(styles.advantagesBlock,{
        [styles.howItWorks]: isFromHowItWorksPage,
    })


    return (
        <div className={advantagesClass}>
            <div className={advantagesBlockClass}>
                <div>
                    <StaticImage
                        width={223}
                        height={166.51}
                        src="../../images/optimized/png/sections/forIndividuals/lead-by-example.png"
                        formats={['AUTO', 'WEBP', 'AVIF']}
                        alt="Download on the App Store"
                        layout="fixed"
                        className={imgWrapperClass}
                        placeholder="tracedSVG"
                    />
                </div>
                <p className={imgTextClass}>Lead By Example</p>
            </div>
            <div className={advantagesBlockClass}>
                <div>
                    <StaticImage
                        width={187}
                        height={182}
                        src="../../images/optimized/png/sections/forIndividuals/make-it-count.png"
                        formats={['AUTO', 'WEBP', 'AVIF']}
                        alt="Download on the App Store"
                        layout="fixed"
                        className={imgWrapperClass}
                        placeholder="tracedSVG"
                    />
                </div>
                <p className={imgTextClass}>Make It Count</p>
            </div>
            <div className={advantagesBlockClass}>
                <div>
                    <StaticImage
                        width={186}
                        height={172}
                        src="../../images/optimized/png/sections/forIndividuals/amplify-your-impact.png"
                        formats={['AUTO', 'WEBP', 'AVIF']}
                        alt="Download on the App Store"
                        layout="fixed"
                        className={imgWrapperClass}
                        placeholder="tracedSVG"
                    />
                </div>
                <p className={imgTextClass}>Amplify Your Impact</p>
            </div>
        </div>
    )
}


Advantages.propTypes = {
    isFromHowItWorksPage: bool,
}

export default Advantages
