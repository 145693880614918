import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Card from '../Card'
import PersonalNote from '../PersonalNote'

import * as styles from './styles.module.scss'

const why = '../../images/optimized/png/sections/about/why.png'
const what = '../../images/optimized/png/sections/about/what.png'

const data = [
    {
        img: (
            <StaticImage
                src={why}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="why image"
                layout="constrained"
                className={styles.question}
                placeholder="tracedSVG"
            />
        ),
        title: 'Why:',
        text:
            'Many people and organizations are at the forefront of positive social impact. Be it individuals, non-profit or for-profit organizations. We don’t want to dictate how, why, or where others should direct their efforts. Quite the opposite, we value individuals’ and organizations’ choice, time and effort that go toward what is universally recognized as positive social impact. We want to support, give recognition and amplify your impact.',
    },
    {
        title: 'What:',
        text:
            'Empower people and organizations to do good, their way. Our purpose is to make resource allocation for social impact more effective, for all parties involved. ',
    },
    {
        img: (
            <StaticImage
                src={what}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="what img"
                layout="constrained"
                className={styles.jar}
                placeholder="tracedSVG"
            />
        ),
        title: 'How:',
        text:
            'Create a platform, that engages volunteers, donors, for-profit and non-profit organizations, in a win-win relationship that produces measurable social impact. Backed by a novel approach to marketing, branding, volunteer management and social impact analytics. While we act as a self sustainable social enterprise, that can generate sufficient resources and attract top notch talent to achieve our goal.',
    },
]

const About = () => {
    return (
        <section className={styles.about}>
            <span id="about-us" className="anchor" />
            <div className="container">
                <h2 className={styles.title}>About Us</h2>
                <p className={styles.subTitle}>
                    Good Your Way, Inc. is a public benefit corporation. <br /> We want to support
                    individuals and charitable organizations who invest in the social sector. And in
                    the long term, make it profitable for businesses to be a part of this effort.
                </p>
                <PersonalNote />
                <ul className={styles.cardList}>
                    {data.map(({ title, text, img }) => (
                        <li key={title} className={styles.cardItem}>
                            <Card title={title} text={text} className="aboutCard">
                                {img}
                            </Card>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}

export default About
